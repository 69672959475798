<template>
  <div class="container">
    <div class="confirmation-container">
      <!-- Mensagem enquanto carrega -->
      <div v-if="loading" class="loading">
        <p>Confirmando seu e-mail, por favor, aguarde...</p>
      </div>

      <!-- Mensagem de sucesso -->
      <div v-else-if="status === 'success'" class="success">
        <h1>Confirmação Bem-Sucedida</h1>
        <p>Seu e-mail foi confirmado com sucesso!</p>
        <router-link to="/login" class="btn-primary">Ir para o Login</router-link>
      </div>

      <!-- Mensagem de erro -->
      <div v-else class="error">
        <h1>Erro na Confirmação</h1>
        <p>{{ errorMessage }}</p>
        <router-link to="/" class="btn-secondary">Voltar para a Página Inicial</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { resolveError } from "@/components/helpers/responseHelper"; // Importe a função de erro

export default {
  name: "EmailConfirmation",
  data() {
    return {
      loading: true,   // Estado de carregamento
      status: null,    // Status de sucesso ou erro
      errorMessage: "", // Mensagem de erro
    };
  },
  async mounted() {
    const { uid, token } = this.$route.params;

    // Verifica se os parâmetros 'uid' ou 'token' estão ausentes
    if (!uid || !token) {
      this.status = "error";
      this.errorMessage = "O link de confirmação do e-mail está incompleto. Por favor, verifique o link enviado.";
      this.loading = false;
      return; // Impede a execução da requisição se os parâmetros estiverem ausentes
    }

    try {
      const apiUrl = `${process.env.VUE_APP_API_URI}/ecom/cliente/confirmar-email/${uid}/${token}`;
      
      // Chama a API para confirmar o e-mail
      const response = await axios.post(apiUrl);
      
      // Se a resposta for bem-sucedida, define o status como 'success'
      if (response.status === 200) {
        this.status = "success";
      } else {
        // Se a resposta não for bem-sucedida, exibe uma mensagem genérica de erro
        this.status = "error";
        this.errorMessage = "Ocorreu um erro ao tentar confirmar o e-mail.";
      }
    } catch (error) {
      // Utilize resolveError para tratar a mensagem de erro
      this.status = "error";
      this.errorMessage = resolveError(error); // Chama a função para resolver o erro
    } finally {
      // Mesmo que haja erro ou sucesso, a página deve parar de carregar
      this.loading = false;
    }
  },
};
</script>

<style scoped>
.container {
  min-height: 70vh;
}

.confirmation-container {
  max-width: 600px;
  margin: 50px auto;
  padding: 20px;
  text-align: center;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.loading p,
.success p,
.error p {
  font-size: 1.2rem;
  color: #666;
}

.success h1 {
  color: #28a745;
}

.error h1 {
  color: #dc3545;
}

.btn-primary,
.btn-secondary {
  padding: 10px 20px;
  text-decoration: none;
  color: #fff;
  border-radius: 5px;
  font-size: 1rem;
  margin-top: 20px;
  display: inline-block;
}

.btn-primary {
  background: #007bff;
}

.btn-primary:hover {
  background: #0056b3;
}

.btn-secondary {
  background: #6c757d;
}

.btn-secondary:hover {
  background: #5a6268;
}
</style>
