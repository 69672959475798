<template>
    <div class="reset-password-container">
      <div v-if="loading" class="loading">
        <p>Carregando...</p>
      </div>
  
      <div v-else>
        <h2>Redefinir Senha</h2>
        <form @submit.prevent="resetPassword" class="reset-password-form">
          <div class="form-group">
            <label for="password">Nova Senha</label>
            <input type="password" id="password" v-model="password" required placeholder="Digite sua nova senha" />
          </div>
  
          <div class="form-group">
            <label for="confirmPassword">Confirmar Nova Senha</label>
            <input type="password" id="confirmPassword" v-model="confirmPassword" required placeholder="Confirme sua nova senha" />
          </div>
  
          <button type="submit" class="btn-submit">Redefinir Senha</button>
        </form>
  
        <!-- Mensagem de erro ou sucesso -->
        <div v-if="status === 'success'" class="success-message">
          <p>Senha alterada com sucesso! Você pode agora <router-link to="/login">fazer login</router-link>.</p>
        </div>
        <div v-if="status === 'error'" class="error-message">
          <p>{{ errorMessage }}</p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    name: 'ResetPasswordView',
    data() {
      return {
        email: this.$route.query.email || '', 
        uuid: this.$route.params.uuid,  
        token: this.$route.params.token, 
        code: this.$route.query.code || '',  
        password: '',
        confirmPassword: '',
        loading: false,
        status: null,
        errorMessage: '',
      };
    },
    methods: {
      async resetPassword() {
        if (this.password !== this.confirmPassword) {
          this.errorMessage = 'As senhas não coincidem. Tente novamente.';
          this.status = 'error';
          return;
        }
  
        try {
          this.loading = true;
  
          console.log('Enviando os seguintes dados:', {
            email: this.email,
            uuid: this.uuid,
            token: this.token,
            code: this.code,
            password: this.password,
            password_confirmation: this.confirmPassword,
          });
  
          const apiUrl = `${process.env.VUE_APP_API_URI}/ecom/cliente/trocar-senha`;
  
          const response = await axios.post(apiUrl, {
            email: this.email,
            uuid: this.uuid,
            token: this.token,
            code: this.code,
            password: this.password,
            password_confirmation: this.confirmPassword,
          });
  
          if (response.status === 200) {
            this.status = 'success';
          } else {
            this.status = 'error';
            this.errorMessage = response.data.message || 'Erro ao redefinir a senha.';
          }
        } catch (error) {
          this.status = 'error';
          this.errorMessage = error.response?.data?.message || 'Ocorreu um erro ao tentar redefinir sua senha.';
        } finally {
          this.loading = false;
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .reset-password-container {
    max-width: 500px;
    margin: 50px auto;
    padding: 30px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    width: 100%;
  }
  
  .loading {
    text-align: center;
  }
  
  .reset-password-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    margin-bottom: 8px;
    font-size: 1rem;
    color: #333;
  }
  
  input {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  button {
    padding: 12px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.1rem;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  .success-message, .error-message {
    margin-top: 20px;
    font-size: 1.2rem;
    text-align: center;
  }
  
  .success-message {
    color: #28a745;
  }
  
  .error-message {
    color: #dc3545;
  }
  
  .error-message p {
    margin-top: 10px;
  }
  </style>
  