import ProdutoView from '@/views/ProdutoView.vue';
import ProdutosView from '@/views/ProdutosView.vue';
import VendedoresView from '@/views/VendedoresView.vue';
import MarcaProdutosView from '@/views/MarcaProdutosView.vue';
import EspecialView from '@/views/EspecialView.vue';
import Checkout from '@/views/ecommerce/Checkout.vue';
import UserConfiguration from '@/views/ecommerce/Usuario/AlterarSenha.vue';
import UserView from '@/views/ecommerce/Usuario/UserView.vue';
import SucessPage from '@/views/ecommerce/pagopar/SucessPage.vue';
import PedidoDetalhes from '@/views/ecommerce/Usuario/PedidoDetalhes.vue';
import Pedidos from '@/views/ecommerce/Usuario/Pedidos.vue';
import PaymentStatus from '@/views/ecommerce/PaymentStatus.vue';

export const ecommerceRoutes = [
  {
    path: '/produto/:slug?',
    name: 'produto',
    component: ProdutoView,
    props: route => ({
      slug: route.params.slug
    })
  },
  {
    path: '/produtos/:categoriaSlug?/:departamentoSlug?',
    name: 'produtos',
    component: ProdutosView,
    props: route => ({
      departamentoSlug: route.params.departamentoSlug,
      categoriaSlug: route.params.categoriaSlug,
      text: route.query.text,
      brandSlug: route.query.brandSlug
    })
  },
  {
    path: '/vendedores',
    name: 'vendedores',
    component: VendedoresView,
    props: true
  },
  {
    path: '/marca/:brandSlug?/:categoriaSlug?',
    name: 'marca',
    component: MarcaProdutosView,
    props: route => ({
      brandSlug: route.params.brandSlug,
      categoriaSlug: route.params.categoriaSlug,
    })
  },
  {
    path: '/especial/:brandSlug?/:categoriaSlug?/:departamentoSlug?',
    name: 'especial',
    component: EspecialView,
    props: route => ({
      brandSlug: route.params.brandSlug,
      categoriaSlug: route.params.categoriaSlug,
      departamentoSlug: route.params.departamentoSlug,
    })
  },
  {
    path: '/perfil',
    name: 'UserView',
    component: UserView,
    meta: { requiresAuth: true },
  },
  {
    path: '/perfil/dados-pessoais',
    name: 'perfil-dados-pessoais',
    component: UserView,
    meta: { requiresAuth: true },
    props: { section: 'dados-pessoais' },
  },
  {
    path: '/perfil/meus-pedidos',
    name: 'perfil-meus-pedidos',
    component: UserView,
    meta: { requiresAuth: true },
    props: { section: 'meus-pedidos' },
  },
  {
    path: '/perfil/endereco',
    name: 'perfil-endereco',
    component: UserView,
    meta: { requiresAuth: true },
    props: { section: 'endereco' },
  },
  {
    path: '/perfil/alterar-senha',
    name: 'perfil-alterar-senha',
    component: UserView,
    meta: { requiresAuth: true },
    props: { section: 'alterar-senha' },
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: Checkout
  },
  {
    path: '/success',
    name: 'success',
    component: SucessPage
  },
  {
    path: '/pedidos',
    name: 'pedidos',
    component: Pedidos,
  },
  {
    path: '/pedido/:external_id',
    name: 'pedido-detalhes',
    component: PedidoDetalhes,
    props: true,
  },
  {
    path: '/checkout/payment',
    name: 'PaymentStatus',
    component: PaymentStatus,
    props: route => ({
      status: route.params.status
    }),
  },
];
