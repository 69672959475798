<template>
  <div class="pedido-detalhes-container">
    <!-- Mensagem de parabéns -->
    <div v-if="paymentCompleted" class="parabens-container">
      <div class="parabens-box">
        <span class="icon-check">🎉</span>
        <h2>{{ $t('orders.payment_success') }}</h2>
        <p>{{ $t('orders.congratulations_message') }}</p>
        <button class="btn-primary" @click="redirectToOrders">{{ $t('orders.view_orders') }}</button>
      </div>
    </div>

    <!-- Loader aguardando pagamento -->
    <div v-else-if="loading" class="loader-container">
      <div class="loader-animation"></div>
      <p>{{ $t('orders.waiting_for_payment') }}</p>
    </div>

    <!-- Mensagem de erro se o pagamento não for encontrado após 30 tentativas -->
    <div v-else class="error-container">
      <p>{{ $t('orders.payment_not_found') }}</p>
      <p>{{ $t('orders.contact_store') }}</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'PedidoDetalhes',
  data() {
    return {
      paymentCompleted: false,  // Status de pagamento completo
      loading: true,  // Status de carregamento
      attempts: 0,  // Contador de tentativas
      maxAttempts: 30,  // Limite de tentativas
      intervalId: null,  // ID do intervalo
      paymentExternalId: localStorage.getItem('paymentExternalId'),
    };
  },
  methods: {
    async fetchPedidoDetails() {
      const token = localStorage.getItem('token');
      if (!token || !this.paymentExternalId) return;

      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URI}/ecom/venda/pedidos/${this.paymentExternalId}/buscar-pagamentos`,
          { headers: { Authorization: `Bearer ${token}` } }
        );

        const pagamentoStatus = response.data?.pagamentos?.[0]?.status;
        if (pagamentoStatus === 'FINALIZADO' || pagamentoStatus === 'PAGO') {
          this.paymentCompleted = true;
          this.loading = false; // Parar o carregamento
          clearInterval(this.intervalId); // Limpar o intervalo
        } else {
          this.attempts++;
          if (this.attempts >= this.maxAttempts) {
            this.loading = false; // Parar o carregamento
            this.showErrorMessage(); // Mostrar mensagem de erro
            clearInterval(this.intervalId); // Limpar o intervalo
          }
        }
      } catch (error) {
        console.error('Erro ao buscar detalhes do pedido.', error);
        this.loading = false; // Parar o carregamento
        this.showErrorMessage(); // Mostrar mensagem de erro
        clearInterval(this.intervalId); // Limpar o intervalo
      }
    },

    showErrorMessage() {
      this.errorMessage = this.$t('orders.payment_not_found'); // Definindo a mensagem de erro
    },

    redirectToOrders() {
      this.$router.push('/perfil/meus-pedidos');
    },
  },
  created() {
    this.fetchPedidoDetails();
    this.intervalId = setInterval(this.fetchPedidoDetails, 5000);
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
};
</script>

<style scoped>
.pedido-detalhes-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  background: #f8f9fa;
  padding: 20px;
  padding-top: 50px;
}

.loader-container {
  text-align: center;
  margin-top: 20px;
}

.loader-animation {
  width: 50px;
  height: 50px;
  border: 4px solid #e0e0e0;
  border-top: 4px solid #007bff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin: 0 auto 1rem;
}

.loader-container p {
  font-size: 1.2rem;
  color: #495057;
  margin-top: 10px;
}

.error-container {
  text-align: center;
  color: #e74c3c;
  font-size: 1.2rem;
  margin-top: 20px;
}

.parabens-container {
  text-align: center;
  background: #ffffff;
  padding: 2rem;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 500px;
  margin-top: 30px;
}

.parabens-box .icon-check {
  font-size: 4rem;
  color: #28a745;
  animation: pop 0.5s ease;
}

.parabens-box h2 {
  font-size: 2rem;
  color: #28a745;
  margin: 1rem 0;
}

.parabens-box p {
  font-size: 1.2rem;
  color: #6c757d;
  margin-bottom: 1.5rem;
}

.btn-primary {
  background-color: #007bff;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-primary:hover {
  background-color: #0056b3;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes pop {
  0% {
    transform: scale(0.9);
    opacity: 0.5;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}
</style>
