<template>
    <div class="success-page">
      <div class="card">
        <div class="icon-wrapper">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 12l2 2 4-4m-7 8a9 9 0 110-18 9 9 0 010 18z"
            />
          </svg>
        </div>
        <h1 class="title">Pagamento Concluído!</h1>
        <p class="message">
          Obrigado por sua compra. Seu pagamento foi processado com sucesso.
        </p>
        <div class="actions">
          <button @click="goToDashboard" class="primary-button">Ir para o Painel</button>
          <button @click="downloadReceipt" class="secondary-button">
            Baixar Comprovante
          </button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "SuccessPage",
    methods: {
      goToDashboard() {
        this.$router.push("/perfil");
      },
      downloadReceipt() {
        alert("Baixando comprovante...");
      },
    },
  };
  </script>
  
  <style>
  .success-page {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #f8f9fa;
    margin: 0;
  }
  
  .card {
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 24px;
    text-align: center;
    width: 100%;
    max-width: 400px;
  }
  
  .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
  }
  
  .icon {
    width: 64px;
    height: 64px;
    color: #4caf50;
  }
  
  .title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 12px;
    color: #333333;
  }
  
  .message {
    font-size: 1rem;
    color: #555555;
    margin-bottom: 24px;
  }
  
  .actions {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  
  .primary-button,
  .secondary-button {
    padding: 12px;
    border-radius: 8px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border: none;
  }
  
  .primary-button {
    background-color: #4caf50;
    color: white;
  }
  
  .primary-button:hover {
    background-color: #43a047;
  }
  
  .secondary-button {
    background-color: #e0e0e0;
    color: #333333;
  }
  
  .secondary-button:hover {
    background-color: #d6d6d6;
  }
  </style>
  