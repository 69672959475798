<template>
  <div class="enderecos-container">
    <h1><i class="fas fa-map-marker-alt"></i> {{ $t('address.info_title') }}</h1>
    <div v-if="enderecos.length" class="enderecos-list">
      <!-- Iterando sobre os Endereços -->
      <div v-for="(endereco, index) in enderecos" :key="index" class="endereco-row">
        <router-link :to="'/endereco/' + endereco.id" class="endereco-link">
          <div class="endereco-detail">
            <strong class="endereco-title">{{ endereco.nome || $t('address.no_name') }}</strong>
          </div>
          <div class="endereco-detail">
            <strong>{{ $t('address.zip_code') }}</strong>
            <span :class="{ 'not-provided': !endereco.cep }">{{ endereco.cep || $t('address.not_informed') }}</span>
          </div>
          <div class="endereco-detail">
            <strong>{{ $t('address.street') }}</strong>
            <span :class="{ 'not-provided': !endereco.logradouro }">{{ endereco.logradouro || $t('address.not_informed') }}</span>
          </div>
          <div class="endereco-detail">
            <strong>{{ $t('address.number') }}</strong>
            <span :class="{ 'not-provided': !endereco.numero }">{{ endereco.numero || $t('address.not_informed') }}</span>
          </div>
          <div class="endereco-detail">
            <strong>{{ $t('address.neighborhood') }}</strong>
            <span :class="{ 'not-provided': !endereco.bairro }">{{ endereco.bairro || $t('address.not_informed') }}</span>
          </div>
          <div class="endereco-detail">
            <strong>{{ $t('address.city') }}</strong>
            <span :class="{ 'not-provided': !endereco.cidade }">{{ endereco.cidade || $t('address.not_informed') }}</span>
          </div>
          <div class="endereco-detail">
            <strong>{{ $t('address.state') }}</strong>
            <span :class="{ 'not-provided': !endereco.estado }">{{ endereco.estado || $t('address.not_informed') }}</span>
          </div>
          <div class="endereco-detail">
            <strong>{{ $t('address.country') }}</strong>
            <span :class="{ 'not-provided': !endereco.pais }">{{ endereco.pais || $t('address.not_informed') }}</span>
          </div>
          <div class="endereco-detail">
            <strong>{{ $t('address.complement') }}</strong>
            <span :class="{ 'not-provided': !endereco.complemento }">{{ endereco.complemento || $t('address.not_informed') }}</span>
          </div>
          <div v-if="endereco.principal" class="principal-badge">
            <i class="fas fa-star"></i> {{ $t('address.main_address') }}
          </div>
        </router-link>
        <button @click="editEndereco(endereco)" class="edit-button">
          <i class="fas fa-edit"></i> {{ $t('address.edit') }}
        </button>
      </div>
    </div>
    <div v-else class="no-endereco">
      <p>{{ $t('address.no_address') }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Enderecos',
  data() {
    return {
      enderecos: [],
    };
  },
  computed: {
    user() {
      return this.$store.getters['auth/getUser'];
    },
  },
  methods: {
    fetchEnderecos() {
      const user = this.user;
      if (user && user.enderecos && Array.isArray(user.enderecos)) {
        this.enderecos = user.enderecos.filter((e) => e.ativo);
      } else {
        this.enderecos = [];
      }
    },
    editEndereco(endereco) {
      console.log('Editando endereço:', endereco);
    }
  },
  created() {
    this.fetchEnderecos();
  },
};
</script>

<style scoped>
.enderecos-container {
  background: #f9f9f9;
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.enderecos-container h1 {
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
  color: #2c3e50;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border-bottom: 2px solid #e0e0e0;
  padding-bottom: 0.5rem;
}

.endereco-row {
  padding: 1.2rem;
  margin-bottom: 1rem;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.endereco-link {
  display: block;
  text-decoration: none;
  color: inherit;
}

.endereco-title {
  font-size: 1.2rem;
  font-weight: bold;
  color: #2c3e50;
}

.endereco-detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.8rem;
  font-size: 1rem;
  color: #34495e;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 0.5rem;
}

.endereco-detail .not-provided {
  color: #e74c3c;
}

.edit-button {
  background-color: #2980b9;
  color: white;
  border: none;
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 0.5rem;
}

.edit-button:hover {
  background-color: #3498db;
}

.no-endereco {
  text-align: center;
  color: #7f8c8d;
  font-size: 1rem;
}

.principal-badge {
  background-color: #f1c40f;
  color: white;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 1rem;
}

@media (max-width: 768px) {
  .enderecos-container {
    padding: 1.5rem;
  }

  .endereco-detail {
    flex-direction: column;
    align-items: flex-start;
  }
}
</style>
