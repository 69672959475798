import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';
import { authRoutes } from './authRoutes';
import { ecommerceRoutes } from './ecommerceRoutes';
import { generalRoutes } from './generalRoutes';

const routes = [
  ...generalRoutes,
  ...ecommerceRoutes,
  ...authRoutes
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  const app = router.app;

  if (app && app.findNameById) {
    if (to.params.categoriaSlug && !isNaN(to.params.categoriaSlug)) {
      to.params.categoriaSlug = app.findNameById(to.params.categoriaSlug, app.categories);
    }

    if (to.params.departamentoSlug && !isNaN(to.params.departamentoSlug)) {
      to.params.departamentoSlug = app.findNameById(to.params.departamentoSlug, app.departments);
    }

    if (to.params.brandSlug && !isNaN(to.params.brandSlug)) {
      to.params.brandSlug = app.findNameById(to.params.brandSlug, app.brands);
    }
  }

  if (from.name) {
    store.dispatch('addRoute', from.fullPath);
  }

  next();
});

export default router;
