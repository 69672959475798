import axios from 'axios';

export default {
  namespaced: true,
  state() {
    return {
      token: localStorage.getItem('token'),
      user: JSON.parse(localStorage.getItem('user')),
    };
  },
  mutations: {
    SET_AUTH(state, { token, user }) {
      if (token) {
        state.token = token;
        const expiry = new Date().getTime() + 3600 * 1000;
        localStorage.setItem('token', token);
        localStorage.setItem('token_expiry', expiry);
      }
      if (user) {
        state.user = user;
      }
    },
    CLEAR_AUTH(state) {
      state.token = null;
      state.user = null;
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.removeItem('token_expiry');
      localStorage.removeItem('ex_cart');
      localStorage.removeItem('cart_id');
    },
  },
  actions: {
    async fetchUser({ state, commit }) {
      try {
        if (!state.token) {
          throw new Error('Token não encontrado. Faça login novamente.');
        }
        const response = await axios.post(`${process.env.VUE_APP_API_URI}/ecom/me`, {}, {
          headers: { Authorization: `Bearer ${state.token}` },
        });
        commit('SET_AUTH', { token: state.token, user: response.data });
      } catch (error) {
        console.error('Erro ao buscar o usuário:', error);
        commit('CLEAR_AUTH');
        throw error;
      }
    },
    saveAuth({ commit }, { token, user }) {
      commit('SET_AUTH', { token, user });
    },
    logout({ commit }) {
      commit('CLEAR_AUTH');
    },
    checkTokenExpiry({ commit }) {
      const expiry = localStorage.getItem('token_expiry');
      if (expiry && new Date().getTime() > expiry) {
        commit('CLEAR_AUTH');
      }
    },
  },
  getters: {
    isAuthenticated(state) {
      const expiry = localStorage.getItem('token_expiry');
      if (expiry && new Date().getTime() > expiry) {
        state.token = null;
        state.user = null;
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('token_expiry');
        return false;
      }
      return !!state.token && expiry && new Date().getTime() < expiry;
    },
    getUser(state) {
      return state.user;
    },
    getToken(state) {
      return state.token;
    },
  },
};
