import { createStore } from 'vuex';
import auth from './auth';
import ecommerce from './ecommerce';
import general from './general';
import language from './language';

const store = createStore({
  modules: {
    auth,
    ecommerce,
    general,
    language,
  },
  state() {
    return {
      isLoading: false, 
    };
  },
  mutations: {
    setLoading(state, status) {
      state.isLoading = status;
    },
  },
  actions: {
    showLoading({ commit }) {
      commit('setLoading', true);
    },
    hideLoading({ commit }) {
      commit('setLoading', false);
    },
  },
  getters: {
    isLoading: (state) => state.isLoading,
  },
});

if (localStorage.getItem('token')) {
  store.dispatch('auth/fetchUser');
}

export default store;
