<template>
  <div class="title-container">
    <h2 class="mb-4">{{ $t('location.title') }}</h2>
    <div class="linha-abaixo-titulo m-2"></div>
  </div>
  <div>
    <a :href="googleMapsLink" target="_blank" class="map-link">
      <img src="@/assets/images/localizacao.png" alt="Location" class="location-image">
      <span class="view-button">{{ $t('viewOnGoogleMaps') }}</span>
    </a>
  </div>
  <div class="spacer"></div> 
</template>


<script setup>
const googleMapsLink = 'https://www.google.com/maps/place/Genove/@-25.5112047,-54.6100773,15z/data=!4m2!3m1!1s0x0:0xae05419777f3a638?sa=X&ved=1t:2428&ictx=111';
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 20px;
}
.spacer {
  height: 50px; 
}
.title-container {
  margin-bottom: 20px;
  text-align: left; 
  width: 100%; 
}

.map-link {
  display: block;
  position: relative;
  text-decoration: none; 
}

.location-image {
  max-width: 100%;
  object-fit: cover;
  border-radius: 10px;
  display: block; 
}

.view-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 0.9rem;
  font-family: 'Lato', sans-serif;
  transition: background-color 0.3s ease;
  pointer-events: none; 
}

.view-button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

h2 {
  font-size: 2rem;
  color: #C99C5A;
  font-weight: bold;
  font-family: 'Lato', sans-serif;
}

.linha-abaixo-titulo {
  height: 4px;
  background-color: #e0e0e0;
  border-radius: 2px;
  margin-top: 5px;
}
</style>
