<template>
  <div class="register-container">
    <h1>{{ $t('userRegistration') }}</h1>
    <div class="progress-bar">
      <div :style="{ width: `${currentStep / 3 * 100}%` }" class="progress-fill"></div>
    </div>
    <form @submit.prevent="submitForm">
      <!-- Passo 1: Dados Pessoais -->
      <div v-if="currentStep === 1">
        <div class="form-group-container">
          <div class="input-wrapper">
            <input type="text" id="nome" v-model="form.nome" placeholder=" " required maxlength="200" />
            <label for="nome">{{ $t('personalData.name') }}</label>
          </div>
          <div class="input-wrapper">
            <input type="text" id="sobrenome" v-model="form.sobrenome" placeholder=" " required maxlength="200" />
            <label for="sobrenome">{{ $t('personalData.surname') }}</label>
          </div>

          <div class="input-wrapper">
            <select id="estrangeiro" v-model="form.estrangeiro" required>
              <option value="" disabled></option>
              <option value="0">{{ $t('yes') }}</option>
              <option value="1">{{ $t('no') }}</option>
            </select>
            <label for="estrangeiro">{{ $t('wantsToBuyWithParaguayanDocuments') }}</label>
          </div>


          <!-- <div v-if="form.estrangeiro === '1'" class="input-wrapper">
            <select id="nacionalidade" v-model="form.nacionalidade" required>
              <option value="" disabled></option>
              <option v-for="pais in paises" :key="pais.id" :value="pais.id">
                {{ pais.nome }}
              </option>
            </select>
            <label for="nacionalidade">{{ $t('personalData.nacionalidade') }}</label>
          </div> -->

          <div class="input-wrapper">
            <input type="email" id="email" v-model="form.email" placeholder=" " required maxlength="255"
              :class="{ error: errors.email }" @blur="checkEmail" />
            <label for="email">{{ $t('email') }}</label>
            <p v-if="errors.email" class="error-message">{{ errors.email }}</p>
          </div>
          <div class="input-wrapper">
            <input type="text" id="dsafggdfgsfdgf" name="sadfsdaf" v-model="form.email_confirmation" placeholder=" "
              required autocomplete="off" :class="{ error: errors.email_confirmation }" />
            <label for="email_confirmation">{{ $t('emailConfirmation') }}</label>
            <p v-if="errors.email_confirmation" class="error-message">{{ errors.email_confirmation }}</p>
          </div>

          <div class="input-wrapper">
            <input type="tel" id="telefone" v-model="form.telefone" placeholder=" " required maxlength="20"
              @input="formatPhone" />
            <label for="telefone">{{ $t('phone') }}</label>
          </div>

          <div class="input-group">
            <div class="input-wrapper">
              <input type="password" id="password" v-model="form.password" placeholder=" " required minlength="8"
                :class="{ error: errors.password }" />
              <label for="password">
                <i class="fas fa-lock"></i> {{ $t('password') }}
              </label>
              <p v-if="errors.password" class="error-message">{{ errors.password }}</p>
            </div>

            <div class="input-wrapper">
              <input type="password" id="password_confirmation" v-model="form.password_confirmation" placeholder=" "
                required minlength="8" :class="{ error: errors.password_confirmation }" autocomplete="new-password" />
              <label for="password_confirmation">
                <i class="fas fa-lock"></i> {{ $t('passwordConfirmation') }}
              </label>
              <p v-if="errors.password_confirmation" class="error-message">
                {{ errors.password_confirmation }}
              </p>
            </div>

          </div>
        </div>
      </div>

      <!-- Passo 2: Endereço -->
      <div v-if="currentStep === 2">
        <div class="form-group-container">
          <div class="input-wrapper">
            <input type="text" id="cep" v-model="form.cep" placeholder=" " @blur="fetchAddress" maxlength="10" />
            <label for="cep">{{ $t('cep') }}</label>
          </div>
          <div class="input-wrapper">
            <input type="text" id="logradouro" v-model="form.logradouro" placeholder=" " maxlength="200" />
            <label for="logradouro">{{ $t('logradouro') }}</label>
          </div>
          <div class="input-wrapper">
            <input type="text" id="numero" v-model="form.numero" placeholder=" " maxlength="200" />
            <label for="numero">{{ $t('Numero') }}</label>
          </div>
          <div class="input-wrapper">
            <input type="text" id="bairro" v-model="form.bairro" placeholder=" " maxlength="60" />
            <label for="bairro">{{ $t('bairro') }}</label>
          </div>
        </div>
        <div class="form-group-container">
          <div class="input-wrapper">
            <input type="text" id="cidade" v-model="form.cidade" placeholder=" " maxlength="100" />
            <label for="cidade">{{ $t('cidade') }}</label>
          </div>
          <div class="input-wrapper">
            <input type="text" id="estado" v-model="form.estado" placeholder=" " maxlength="10" />
            <label for="estado">{{ $t('estado') }}</label>
          </div>
          <div class="input-wrapper">
            <input type="text" id="pais" v-model="form.pais" placeholder=" " maxlength="20" />
            <label for="pais">{{ $t('pais') }}</label>
          </div>
          <div class="input-wrapper">
            <input type="text" id="complemento" v-model="form.complemento" placeholder=" " maxlength="200" />
            <label for="complemento">{{ $t('complemento') }}</label>
          </div>
        </div>
      </div>

      <!-- Passo 3: Documentação -->
      <div v-if="currentStep === 3">
        <div class="form-group-container">
          <div class="input-wrapper">
            <select id="documento_tipo" v-model="form.documento_tipo" required v-if="form.estrangeiro === '1'">
              <option value="" disabled selected></option>
              <option value="CPF">{{ $t('cpf') }}</option>
              <option value="PASSAPORTE">{{ $t('passport') }}</option>
              <option value="OUTRO">{{ $t('other') }}</option>
            </select>
            <input type="text" v-else value="RUC" readonly />
            <label for="documento_tipo">{{ $t('documentType') }}</label>
          </div>
          <div class="input-wrapper">
            <input type="text" id="documento" v-model="form.documento" placeholder=" " required maxlength="20" />
            <label for="documento">{{ $t('documentNumber') }}</label>
          </div>
        </div>
        <h3 class="section-title">{{ $t('registration.identityTitle') }}</h3>
        <div class="form-group-container">
          <!-- Campo para número da identidade -->
          <div class="input-wrapper">
            <input type="text" id="identidade" v-model="form.identidade" placeholder=" " required maxlength="20" />
            <label for="identidade">{{ $t('registration.identityNumber') }}</label>
          </div>

          <!-- Campo para órgão emissor (somente para estrangeiro) -->
          <div class="input-wrapper" v-if="form.estrangeiro === '1'">
            <input type="text" id="complementoIdentidade" v-model="form.complementoIdentidade" placeholder=" "
              maxlength="100" />
            <label for="complementoIdentidade">{{ $t('registration.issuingAgency') }}</label>
          </div>
        </div>

        <div class="form-group-container">
          <!-- Upload de imagem frente -->
          <div class="input-wrapper">
            <label for="frente" class="file-upload-label">
              {{ $t('registration.frontImage') }}
            </label>
            <input type="file" id="frente" @change="uploadFile('frente', $event)" />
            <div v-if="frentePreview" class="image-preview">
              <img :src="frentePreview" alt="Preview da frente" />
            </div>
          </div>

          <!-- Upload de imagem verso -->
          <div class="input-wrapper">
            <label for="verso" class="file-upload-label">
              {{ $t('registration.backImage') }}
            </label>
            <input type="file" id="verso" @change="uploadFile('verso', $event)" />
            <div v-if="versoPreview" class="image-preview">
              <img :src="versoPreview" alt="Preview do verso" />
            </div>
          </div>
        </div>

      </div>
      <div v-if="statusMessage" class="status-message">
        <p :class="{ 'success': isSuccess, 'error': !isSuccess }">{{ statusMessage }}</p>
      </div>
      <p v-if="Object.keys(errors).length > 0" class="error-message">
        {{ $t('pleaseFillAllFields') }}
      </p>
      <div v-if="statusMessage" class="status-message">
        <p :class="{ 'success': isSuccess, 'error': !isSuccess }">{{ statusMessage }}</p>
      </div>

      <div class="form-navigation">
        <button type="button" @click="previousStep" v-if="currentStep > 1">{{ $t('previous') }}</button>
        <button type="button" @click="nextStep" v-if="currentStep < 3">{{ $t('next') }}</button>
        <button type="submit" v-if="currentStep === 3">{{ $t('register') }}</button>
      </div>

    </form>
  </div>
</template>

<script>
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { eventBus } from '@/eventBus';
import ptBR from '@/locales/pt-br.json';
import esPY from '@/locales/es-py.json';
import { resolveError } from '@/components/helpers/responseHelper'

export default {
  data() {
    return {
      currentStep: 1,
      form: {
        nome: '',
        sobrenome: '',
        email: '',
        email_confirmation: '',
        estrangeiro: '',
        nacionalidade: '',
        telefone: '',
        documento_tipo: 'RUC',
        documento: '',
        identidade: '',
        identidade_complemento: '',
        password: '',
        password_confirmation: '',
        cep: '',
        logradouro: '',
        numero: '',
        complemento: '',
        bairro: '',
        cidade: '',
        estado: '',
        pais: '',
        frente: null,
        verso: null,
      },
      paises: [],
      errors: {},
      emailCheckTimeout: null,
      successMessage: '',
      isSubmitting: false,
      statusMessage: '',
      isSuccess: false,
      frentePreview: null,
      versoPreview: null,
    };
  },
  watch: {
    'form.email'(newValue) {
      if (this.errors.email) {
        this.errors.email = '';
      }

      if (this.emailCheckTimeout) clearTimeout(this.emailCheckTimeout);
      this.emailCheckTimeout = setTimeout(() => {
        if (newValue) {
          this.checkEmail();
        }
      }, 500);
    },
  },
  methods: {
    async fetchCountries() {
      try {
        const response = await axios.get('https://api-genove.maickel.dev/api/administracao/paises');
        this.paises = response.data;
      } catch (error) {
        console.error("Erro ao carregar países:", error);
        this.paises = [];
      }
    },

    isImage(file) {
      if (!file || !file.type) return false;
      return file.type.startsWith("image/");
    },

    removeFile(field) {
      this.form[field] = null;
      this.form[`${field}Url`] = "";
    },
    handleEstrangeiroChange() {
      if (this.form.estrangeiro === '0' || this.form.estrangeiro === '1') {
        this.form.documento_tipo = 'RUC';
      } else {
        this.form.documento_tipo = '';
      }
    },


    changeLanguage(lang) {
      this.$i18n.locale = lang;
      if (lang === 'pt-br') {
        this.$i18n.setLocaleMessage('pt-br', ptBR);
      } else if (lang === 'es-py') {
        this.$i18n.setLocaleMessage('es-py', esPY);
      }
    },

    previousStep() {
      if (this.currentStep > 1) {
        this.currentStep--;
      }
    },

    async nextStep() {
      if (await this.validateStep(this.currentStep)) {
        this.currentStep++;
      }
    },

    async validateStep(step) {
      this.errors = {};

      switch (step) {
        case 1:
          if (!this.form.nome) this.errors.nome = this.$t('nomeRequired');
          if (!this.form.email) this.errors.email = this.$t('emailRequired');
          if (!this.form.email_confirmation) {
            this.errors.email_confirmation = this.$t('emailConfirmationRequired');
          } else if (this.form.email !== this.form.email_confirmation) {
            this.errors.email_confirmation = this.$t('emailConfirmationMismatch');
          }
          if (!this.form.telefone) this.errors.telefone = this.$t('telefoneRequired');

          if (!this.form.password) {
            this.errors.password = this.$t('passwordRequired');
          } else if (this.form.password.length < 8) {
            this.errors.password = this.$t('passwordMinLength');
          }

          if (!this.form.password_confirmation) {
            this.errors.password_confirmation = this.$t('passwordConfirmationRequired');
          } else if (this.form.password !== this.form.password_confirmation) {
            this.errors.password_confirmation = this.$t('passwordConfirmationMismatch');
          }

          if (!this.form.estrangeiro) this.errors.estrangeiro = this.$t('estrangeiroRequired');

          if (!this.errors.email) {
            const emailExists = await this.checkEmail();
            if (emailExists) {
              this.errors.email = this.$t('emailInUse');
            }
          }
          break;

        case 2:
          if (this.form.pais === "Paraguai") {
            if (!this.form.pais) this.errors.pais = this.$t('pais');
            if (!this.form.estado) this.errors.estado = this.$t('estado');
            if (!this.form.cidade) this.errors.cidade = this.$t('cidade');
            if (!this.form.bairro) this.errors.bairro = this.$t('bairro');
          }
          break;

        case 3:
          if (!this.form.documento_tipo) this.errors.documento_tipo = this.$t('documentTypeRequired');
          if (!this.form.documento) this.errors.documento = this.$t('documentNumberRequired');
          if (!this.form.identidade) this.errors.identidade = this.$t('identityNumberRequired');
          if (!this.form.frente) this.errors.frente = this.$t('frontRequired');
          if (!this.form.verso) this.errors.verso = this.$t('backRequired');
          break;

        default:
          break;
      }

      return Object.keys(this.errors).length === 0;
    },

    async checkEmail() {
      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URI}/ecom/cliente/cadastrar/check-email`, {
          email: this.form.email,
        });
        return false;
      } catch (error) {
        if (error.response?.status === 422) {
          this.errors.email = this.$t('emailInUse');
          return true;
        }
        console.error("Erro ao verificar e-mail:", error);
        this.errors.email = this.$t('emailCheckError');
        return true;
      }
    },

    async fetchAddress() {
      if (this.form.cep) {
        try {
          const response = await fetch(`https://viacep.com.br/ws/${this.form.cep}/json/`);
          const data = await response.json();
          if (data.erro) throw new Error(this.$t('cepNotFound'));
          this.form.logradouro = data.logradouro;
          this.form.bairro = data.bairro;
          this.form.cidade = data.localidade;
          this.form.estado = data.uf;
        } catch (error) {
          this.errors.cep = this.$t('addressFetchError');
        }
      }
    },

    async submitForm() {
      this.isSubmitting = true;
      this.statusMessage = "";

      try {
        let formData = new FormData();
        Object.keys(this.form).forEach((key) => {
          if (key === 'frente' || key === 'verso') {
            if (this.form[key]) {
              formData.append(key, this.form[key]);
            }
          } else {
            formData.append(key, this.form[key]);
          }
        });

        const registerResponse = await axios.post(
          `${process.env.VUE_APP_API_URI}/ecom/cliente/cadastrar`,
          formData
        );

        if (registerResponse.status !== 200) {
          throw new Error(resolveError(registerResponse));
        }

        this.statusMessage = this.$t('registerSuccess');
        this.isSuccess = true;

        const loginResponse = await axios.post(
          `${process.env.VUE_APP_API_URI}/ecom/login`,
          {
            email: this.form.email,
            password: this.form.password,
          }
        );

        if (loginResponse.status !== 200) {
          throw new Error(resolveError(loginResponse));
        }

        const loginData = loginResponse.data;
        this.$store.dispatch('auth/saveAuth', {
          token: loginData.token,
          user: loginData.user,
        });

        const redirectRoute = this.$route.query.redirect || '/perfil';
        this.$router.push(redirectRoute);
      } catch (error) {
        this.statusMessage = `${this.$t('errorMessage')}: ${error.message}`;
        this.isSuccess = false;
        console.error('Erro no cadastro/login:', error.message);
      } finally {
        this.isSubmitting = false;
      }
    },

    watch: {
      'form.estrangeiro': 'handleEstrangeiroChange',
    },
    resetForm() {
      this.form = {
        nome: '',
        email: '',
        estrangeiro: '',
        telefone: '',
        documento_tipo: '',
        documento: '',
        identidade: '',
        identidade_complemento: '',
        password: '',
        password_confirmation: '',
        cep: '',
        logradouro: '',
        complemento: '',
        bairro: '',
        cidade: '',
        estado: '',
        pais: '',
        frente: null,
        verso: null,
      };
      this.currentStep = 1;
      this.errors = {};
    },

    uploadFile(field, event) {
      const file = event.target.files[0];
      if (!file) return;

      this.form[field] = file;

      if (this.isImage(file)) {
        const reader = new FileReader();
        reader.onload = () => {
          if (field === 'frente') {
            this.frentePreview = reader.result;
          } else if (field === 'verso') {
            this.versoPreview = reader.result;
          }
        };
        reader.readAsDataURL(file);
      }
    },
  },
};
</script>

<style scoped>
.register-container {
  max-width: 700px;
  margin: 50px auto;
  padding: 40px;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
  font-family: 'Roboto', sans-serif;
}

h1 {
  text-align: center;
  font-size: 32px;
  color: #333;
  font-weight: 700;
  margin-bottom: 20px;
}

.progress-bar {
  background: #e0e0e0;
  height: 10px;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 20px;
}

.progress-fill {
  height: 100%;
  background: linear-gradient(90deg, #6c63ff, #9a86e3);
  transition: width 0.4s ease;
}

.form-group-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 25px;
  margin-bottom: 25px;
}

.input-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
}

.input-wrapper input,
.input-wrapper select {
  padding: 14px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  transition: all 0.3s ease;
  box-sizing: border-box;
}

.section-title {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 20px;
}

.input-wrapper input:focus,
.input-wrapper select:focus {
  border-color: #6c63ff;
  box-shadow: 0 0 6px rgba(108, 99, 255, 0.3);
}

.input-wrapper label {
  position: absolute;
  top: 50%;
  left: 16px;
  font-size: 16px;
  color: #888;
  pointer-events: none;
  transform: translateY(-50%);
  transition: 0.3s ease;
}

.input-wrapper input:focus+label,
.input-wrapper input:not(:placeholder-shown)+label,
.input-wrapper select:focus+label,
.input-wrapper select:not([value=""]):valid+label {
  top: 0;
  font-size: 14px;
  color: #6c63ff;
  transform: translateY(-20px);
}

.input-wrapper input.error,
.input-wrapper select.error {
  border-color: #dc3545;
  background-color: #f8d7da;
}

.input-wrapper input.error:focus,
.input-wrapper select.error:focus {
  box-shadow: 0 0 6px rgba(220, 53, 69, 0.5);
}

.input-group {
  display: flex;
  gap: 20px;
}

.form-navigation {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  gap: 15px;
}

button {
  padding: 12px 20px;
  font-size: 16px;
  font-weight: 600;
  border: none;
  border-radius: 12px;
  background-color: #6c63ff;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #5a54d1;
}

button:active {
  transform: translateY(2px);
}

button[disabled] {
  background-color: #b0b0b0;
  cursor: not-allowed;
}

.error-message {
  color: #dc3545;
  font-size: 0.9rem;
  margin-top: 0.3rem;
}

@media (max-width: 768px) {
  .form-group-container {
    grid-template-columns: 1fr;
  }
}

.input-wrapper {
  margin-bottom: 20px;
}

.file-upload-label {
  font-weight: bold;
  display: block;
  margin-top: -20px
}

.image-preview {
  margin-top: 10px;
  /* Espaço entre o campo e o preview */
}

.image-preview img {
  max-width: 100%;
  /* Ajusta a largura da imagem ao contêiner */
  max-height: 150px;
  /* Define uma altura máxima */
  border: 1px solid #ccc;
  /* Adiciona uma borda ao redor do preview */
  border-radius: 4px;
  /* Bordas arredondadas */
  object-fit: contain;
  /* Ajusta a imagem para caber sem distorção */
}

.uploaded-file-preview {
  margin-top: 10px;
  color: green;
}

input[type="file"] {
  display: block;
  margin-top: 10px;
}
</style>