export default {
  state: {
    currentLanguage: localStorage.getItem('locale') || 'pt-BR', 
  },
  mutations: {
    setLanguage(state, language) {
      state.currentLanguage = language;
    },
  },
  actions: {
    setLanguageBasedOnUser({ commit }, user) {
      if (user && user.extrangeiro !== undefined) {
        const language = user.extrangeiro ? 'es-PY' : 'pt-BR';
        console.log('Alterando idioma para:', language); 
        commit('setLanguage', language);
        localStorage.setItem('locale', language); 
      }
    },
  },
  
  getters: {
    currentLanguage: (state) => state.currentLanguage,
  },
};
