<template>
  <div :class="['side-menu', { 'menu-open': menuOpen, 'menu-closing': isClosing }]">
    <button class="back-button" @click="closeMenu">&larr;</button>
    <nav>
      <ul>
      <!-- Exibindo todas as outras categorias (exceto PERFUMARIA e VAPE) -->
      <li v-for="category in otherCategories" :key="category.id" class="menu-item-has-children">
          <a href="#" @click.prevent="selectCategory(category)" class="category-link">{{ category.name }}</a>
          <ul class="sub-menu">
            <li v-for="department in category.departments" :key="department.id">
              <a 
                href="#" 
                @click.prevent="selectDepartment(department, category)" 
                class="department-link">
                {{ department.name }}
              </a>
            </li>
          </ul>
        </li>
         
        <!-- Divider -->
        <li class="menu-divider"></li>

        <!-- Opções de marcas e rota especial -->
        <li v-for="option in menuOptions" :key="option.id" class="brand-link">
          <a 
            href="#" 
            @click.prevent="handleOptionSelection(option)" 
            :class="{ 'special-option': option.type === 'special' }">
            {{ option.name }}
          </a>
        </li>

    
        
      </ul>
    </nav>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'MobileMenu',
  props: {
    menuOpen: Boolean
  },
  data() {
    return {
      menuOptions: [], 
      allCategories: [],
      specificCategories: [], 
      otherCategories: [], 
      isClosing: false
    };
  },
  async created() {
    try {
      // Carregar marcas específicas
      const brandsResponse = await axios.get(`${process.env.VUE_APP_API_URI}/api/public/brands`);
      const brands = brandsResponse.data
        .filter(brand => ['APPLE', 'XIAOMI', 'HONOR'].includes(brand.nome))
        .map(brand => ({
          id: brand.id,
          name: brand.nome,
          slug: brand.slug,
          type: 'brand' // Identificar como marca
        }));

      // Carregar todas as categorias
      const categoriesResponse = await axios.get(`${process.env.VUE_APP_API_URI}/api/public/categories`);
      const categories = categoriesResponse.data.map(category => ({
        id: category.id,
        name: category.name,
        slug: category.slug,
        departments: category.departments || [], // Certifique-se de que os departamentos existam
        type: 'category' // Identificar como categoria
      }));

      this.specificCategories = categories.filter(category => ['PERFUMARIA', 'VAPE'].includes(category.name.toUpperCase()));

      this.otherCategories = categories.filter(category => !['PERFUMARIA', 'VAPE'].includes(category.name.toUpperCase()));
      this.otherCategories.sort((a, b) => a.name.localeCompare(b.name));


      // Adicionar a opção especial "XO"
      const xoOption = {
        id: 'xo',
        name: 'XO',
        slug: 'xo',
        type: 'special' // Identificar como rota especial
      };

      // Combinar todas as opções
      this.menuOptions = [...brands, ...this.specificCategories, xoOption];

    } catch (error) {
      console.error('Erro ao buscar opções do menu:', error);
    }
  },
  methods: {
    closeMenu() {
      this.isClosing = true;
      setTimeout(() => {
        this.$emit('toggle-menu');
        this.isClosing = false;
      }, 300); 
    },
    handleOptionSelection(option) {
      if (option.type === 'special') {
        this.$router.push({
          path: `/especial/${option.slug}`
        });
      } else if (option.type === 'brand') {
        this.$router.push({
          name: 'marca',
          params: { brandSlug: option.slug }
        });
      } else if (option.type === 'category') {
        this.$router.push({
          name: 'produtos',
          params: { categoriaSlug: option.slug }
        });
      }
      this.closeMenu();
    },
    selectCategory(category) {
      this.$router.push({
        name: 'produtos',
        params: { categoriaSlug: category.slug }
      });
      this.closeMenu(); 
    },
    selectDepartment(department, category) {
      this.$router.push({
        name: 'produtos',
        params: {
          categoriaSlug: category.slug,
          departamentoSlug: department.slug
        }
      });
      this.closeMenu(); 
    }
  }
};
</script>

<style scoped>
.side-menu {
  position: fixed;
  top: 0;
  left: -100%;
  width: 90%;
  height: 100%;
  background-color: #fff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  transition: left 0.3s ease;
  z-index: 1000;
  color: #333;
}

.side-menu.menu-open {
  left: 0;
}

.side-menu.menu-closing {
  left: -100%;
}

.back-button {
  background: none;
  border: none;
  font-size: 32px;
  color: #333;
  cursor: pointer;
  position: absolute;
  top: 20px;
  left: 20px;
}

.side-menu nav {
  padding: 80px 20px 20px 20px;
}

.side-menu ul {
  list-style: none;
  padding: 0;
}

.side-menu ul li {
  margin-bottom: 10px;
  position: relative;
}

.brand-link a {
  text-decoration: none;
  color: #C99C5A;
  font-size: 18px;
  font-weight: bold;
  display: block;
  padding: 10px 15px;
  background-color: rgba(201, 156, 90, 0.2);
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
  text-transform: uppercase;
}

.brand-link a:hover {
  background-color: #C99C5A;
  color: #fff;
}

.menu-divider {
  border-top: 1px solid #ccc;
  margin: 10px 0;
}

.category-link {
  text-decoration: none;
  color: #333;
  font-size: 20px;
  font-weight: bold;
  display: block;
  padding: 10px 15px;
  background-color: rgba(201, 156, 90, 0.1);
  border-left: 4px solid #C99C5A;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.category-link:hover {
  background-color: #C99C5A;
  color: #fff;
}

.department-link {
  text-decoration: none;
  color: #666;
  font-size: 16px;
  display: block;
  padding: 8px 20px;
  transition: background-color 0.3s, color 0.3s;
  border-radius: 5px;
  margin-left: 15px;
}

.department-link:hover {
  background-color: rgba(201, 156, 90, 0.2);
  color: #333;
}

.side-menu .sub-menu {
  padding-left: 0;
  display: none;
  transition: max-height 0.3s ease;
  overflow: hidden;
  max-height: 0;
}

.menu-item-has-children:hover .sub-menu {
  display: block;
  max-height: 200px;
}

/* Adiciona a setinha somente nos links das categorias que possuem submenus */
.menu-item-has-children > a::after {
  content: '▼';
  position: absolute;
  right: 10px;
  font-size: 12px;
  transition: transform 0.3s;
}

/* Remove a setinha dos departamentos */
.department-link::after {
  content: none;
}

.menu-item-has-children:hover > a::after {
  transform: rotate(-180deg);
}

</style>

