<template>
  <div class="pedidos-container">
    <h1><i class="fas fa-shopping-cart"></i> {{ $t('orders.pedidos') }}</h1>
    
    <!-- Loading state -->
    <div v-if="isLoading" class="loading">
      <i class="fas fa-spinner fa-spin"></i> {{ $t('orders.loading') }}
    </div>
    
    <!-- Error message if there are no orders or an error occurs -->
    <div v-if="errorMessage" class="error-message">
      <p>{{ errorMessage }}</p>
    </div>

    <div class="pedido-row" v-for="pedido in pedidos" :key="pedido.id">
      <router-link :to="'/pedido/' + pedido.external_id" class="pedido-link">
        <div class="pedido-detail">
          <strong class="pedido-title">{{ $t('orders.pedido') }} #{{ pedido.pedido }}</strong>
        </div>
        <div class="pedido-detail">
          <strong>{{ $t('orders.date') }}</strong>
          <span>{{ formatDate(pedido.created_at) }}</span>
        </div>
        <div class="pedido-detail">
          <strong>{{ $t('orders.total') }}</strong>
          <span>{{ formatCurrency(pedido.valor_total, pedido.moeda_id) }}</span>
        </div>
        <div class="pedido-detail">
          <strong>{{ $t('orders.status') }}</strong>
          <span :class="statusClass(pedido.status)">
            {{ statusLabel(pedido.status) }}
          </span>
        </div>
        <hr class="divider" />
      </router-link>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "Pedidos",
  data() {
    return {
      pedidos: [],
      errorMessage: "",
      isLoading: true,
    };
  },
  computed: {
    ...mapGetters(["getToken"]),
  },
  methods: {
    formatDate(dateString) {
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      };
      return new Date(dateString).toLocaleString("pt-BR", options);
    },
    formatCurrency(value, moedaId) {
      let currencySymbol = '';
      let formattedValue = parseFloat(value).toFixed(2);

      switch (moedaId) {
        case 1:
          currencySymbol = 'U$';
          break;
        case 2:
          currencySymbol = 'R$';
          break;
        case 3:
          currencySymbol = 'G$';
          break;
        default:
          currencySymbol = '';
      }

      return `${currencySymbol} ${formattedValue}`;
    },
    async fetchPedidos() {
      const token = localStorage.getItem("token");

      if (!token) {
        this.errorMessage = this.$t('orders.token_error');
        this.isLoading = false;
        return;
      }

      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URI}/ecom/venda/pedidos`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data && response.data.data && response.data.data.length > 0) {
          this.pedidos = response.data.data;
        } else {
          this.errorMessage = this.$t('orders.no_orders');
        }
      } catch (error) {
        this.errorMessage = this.$t('orders.fetch_error');
      } finally {
        this.isLoading = false;
      }
    },
    statusClass(status) {
      switch (status) {
        case "AGUARDANDO":
          return "awaiting";
        case "ENVIADO":
          return "shipped";
        case "AUTORIZADO":
          return "authorized";
        case "PAGO":
          return "paid";
        case "ESTORNADO":
          return "refunded";
        case "PAGAMENTO":
          return "pending";
        default:
          return "";
      }
    },
    statusLabel(status) {
      switch (status) {
        case "AGUARDANDO":
          return this.$t('orders.awaiting');
        case "ENVIADO":
          return this.$t('orders.shipped');
        case "AUTORIZADO":
          return this.$t('orders.authorized');
        case "PAGO":
          return this.$t('orders.paid');
        case "ESTORNADO":
          return this.$t('orders.refunded');
        case "PAGAMENTO":
          return this.$t('orders.awaiting_payment');
        default:
          return status;
      }
    },
  },
  async created() {
    await this.fetchPedidos();
  },
};
</script>
<style scoped>

.pedidos-container {
  background: #f9f9f9;
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.pedido-row {
  padding: 1rem 2rem; 
  margin-bottom: 1rem;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
  width: 100%; 
  display: flex;
  flex-direction: column;
}

.pedido-link {
  display: block;
  text-decoration: none;
  color: inherit;
  padding: 1rem;
  border-radius: 5px;
}

h1 {
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
  color: #2c3e50;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border-bottom: 2px solid #e0e0e0;
  padding-bottom: 0.5rem;
}

h1 i {
  font-size: 1.5rem;
  color: #3498db;
}

.loading {
  text-align: center;
  color: #3498db;
  font-size: 1.5rem;
  margin-top: 2rem;
}

.no-pedidos,
.error-message {
  text-align: center;
  color: #7f8c8d;
  font-size: 1.2rem;
  margin-top: 2rem;
}

.no-pedidos i {
  font-size: 2rem;
  color: #e74c3c;
}

.pedido-title {
  font-size: 1.4rem;
  font-weight: bold;
  color: #2c3e50;
  margin-right: 0.5rem;
  text-transform: uppercase;
}


.pedido-row:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.pedido-row:hover .divider {
  border-top: 1px solid #ffffff !important;
}

.pedido-detail {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid rgb(212, 212, 212);
  padding-bottom: 0.5rem;
}

.pedido-detail:last-child {
  border-bottom: none;
}

.pedido-detail strong {
  color: #34495e;
}

.pedido-detail span {
  color: #7f8c8d;
}

.details-link {
  text-decoration: none;
  font-weight: bold;
  color: #3498db;
  display: inline-flex;
  align-items: center;
  margin-top: 1rem;
}

.details-link:hover {
  color: #2980b9;
}

.divider {
  border: none;
  border-top: 1px solid #e0e0e0;
  margin-top: 1rem;
}

.awaiting {
  color: #f39c12;
  background-color: rgba(243, 156, 18, 0.1);
  padding: 0.2rem 0.5rem;
  border-radius: 5px;
}

.shipped {
  color: #2980b9;
  background-color: rgba(41, 128, 185, 0.1);
  padding: 0.2rem 0.5rem;
  border-radius: 5px;
}

.authorized,
.paid {
  color: #2ecc71;
  background-color: rgba(46, 204, 113, 0.1);
  padding: 0.2rem 0.5rem;
  border-radius: 5px;
}

.refunded {
  color: #e74c3c;
  background-color: rgba(231, 76, 60, 0.1);
  padding: 0.2rem 0.5rem;
  border-radius: 5px;
}

.pending {
  color: #f1c40f;
}

.divider {
  border: none;
  border-top: 1px solid #e0e0e0;
  margin-top: 1rem;
}
</style>
