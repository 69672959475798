import axios from 'axios';
import store from '@/store';

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URI,
});

// Função para obter o moedaId com base no idioma atual
const getMoedaId = (currentLanguage) => {
  switch (currentLanguage) {
    case 'es-PY':
      return 3;
    case 'pt-BR':
      return 2;
    default:
      return 1;
  }
};

// Função para obter o símbolo da moeda
const getCurrencySymbol = (moedaId) => {
  switch (moedaId) {
    case 3:
      return 'G$';
    case 2:
      return 'R$';
    default:
      return 'U$';
  }
};

// Interceptador para adicionar moeda_id automaticamente
api.interceptors.request.use((config) => {
  const currentLanguage = store.state.language.currentLanguage || 'pt-BR';
  const moedaId = getMoedaId(currentLanguage);
  config.params = { ...config.params, moeda_id: moedaId };
  return config;
});


// Função para buscar produtos com parâmetros dinâmicos
export const fetchProducts = async (otherParams = {}) => {
  store.dispatch('general/setLoading', true);
  try {
    // Obter o moeda_id a partir dos parâmetros passados ou da linguagem atual
    const moedaId = otherParams.moeda_id || getMoedaId(store.state.language.currentLanguage);

    // Atualizar a moeda no Vuex
    store.commit('setMoedaId', moedaId);

    // Enviar a requisição para a API com o moeda_id
    const response = await api.get('api/public/products', { params: { ...otherParams, moeda_id: moedaId } });
    const productData = response.data;

    // Formatar os preços de cada produto
    productData.data.forEach(product => {
      if (product.preco_atual && product.moeda) {
        const price = parseFloat(product.valor_venda);
        const symbol = product.moeda.simbolo;
        const formattedPrice = price.toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });

        // Adicionar o preço formatado ao objeto do produto
        product.preco_atual.formatado = `${symbol} ${formattedPrice}`;
      }
    });

    // Retornar os dados com os preços já formatados
    return productData;
  } catch (error) {
    console.error('Erro ao buscar produtos:', error);
    throw error;
  } finally {
    store.dispatch('general/setLoading', false);
  }
};


// Função para buscar detalhes de um produto específico
export const fetchProductDetails = async (slug) => {
  store.dispatch('general/setLoading', true);
  try {
    const response = await api.get(`api/public/products/${slug}/details`);
    const productData = response.data;

    // Formatar o preço na API antes de retornar
    if (productData.preco_atual && productData.moeda) {
      const price = parseFloat(productData.preco_atual.valor_venda);
      const symbol = productData.moeda.simbolo;
      const formattedPrice = price.toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });

      // Adicionar o preço formatado ao objeto do produto
      productData.preco_atual.formatado = `${symbol} ${formattedPrice}`;
    }

    return productData;
  } catch (error) {
    console.error('Erro ao buscar detalhes do produto:', error);
    throw error;
  } finally {
    store.dispatch('general/setLoading', false);
  }
};

// Função para buscar produtos de uma marca específica usando o brand_id
export const fetchProductsByBrandId = async (brandId, otherParams = {}) => {
  store.dispatch('general/setLoading', true);
  try {
    const response = await api.get('api/public/products', {
      params: { brand_id: brandId, ...otherParams },
    });
    const moedaId = otherParams.moeda_id || getMoedaId(store.state.language.currentLanguage);
    store.commit('setMoedaId', moedaId);
    return response.data;
  } catch (error) {
    console.error('Erro ao buscar produtos da marca:', error);
    throw error;
  } finally {
    store.dispatch('general/setLoading', false);
  }
};

// Função para buscar categorias
export const fetchCategories = async () => {
  store.dispatch('general/setLoading', true);
  try {
    const response = await api.get('api/public/categories');
    return response.data;
  } catch (error) {
    console.error('Erro ao buscar categorias:', error);
    throw error;
  } finally {
    store.dispatch('general/setLoading', false);
  }
};

// Função para buscar marcas
export const fetchBrands = async () => {
  store.dispatch('general/setLoading', true);
  try {
    const response = await api.get('api/public/brands');
    return response.data;
  } catch (error) {
    console.error('Erro ao buscar marcas:', error);
    throw error;
  } finally {
    store.dispatch('general/setLoading', false);
  }
};


// Getter para obter o símbolo da moeda
export const getPriceWithCurrency = (price) => {
  const moedaId = store.state.moedaId || getMoedaId(store.state.language.currentLanguage);
  const symbol = getCurrencySymbol(moedaId);
  return `${symbol} ${price}`;
};
