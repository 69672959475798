<template>
  <div 
    class="slider-de-marcas mb-50" 
    @mouseover="pauseSlider"
    @mouseout="resumeSlider"
  >
    <h2 class="mb-4">{{ $t('brands.title') }}</h2>
    <div class="linha-abaixo-titulo m-2"></div>
    <div class="container mt-4">
      <div class="lista-de-marcas" ref="listaDeMarcas">
        <div
          v-for="marca in marcas.filter(m => m.logo)"
          :key="marca.id"
          class="marca-unica"
        >
          <a href="#" @click.prevent="selectBrand(marca)">
            <div class="logo-container">
              <img
                :src="marca.logo"
                :alt="marca.nome"
              />
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { fetchBrands } from '../../services/apiService'; 

export default {
  name: 'BrandSlider',
  data() {
    return {
      marcas: [],
      position: 0,
      sliderPaused: false,
      animationFrameId: null,
      scrollSpeed: 0.2
    };
  },
  async mounted() {
    await this.fetchMarcas();
    this.$nextTick(() => {
      // Inicie o slider apenas se a referência estiver disponível
      if (this.$refs.listaDeMarcas) {
        this.startSlider();
      } else {
        // Aguarde um pouco e tente iniciar o slider novamente
        setTimeout(this.startSlider, 100);
      }
    });
  },
  beforeUnmount() {
    if (this.animationFrameId) {
      cancelAnimationFrame(this.animationFrameId);
    }
  },
  methods: {
    async fetchMarcas() {
      try {
        const data = await fetchBrands();
        this.marcas = data;
        this.marcas = [...this.marcas, ...this.marcas]; 
      } catch (error) {
        console.error('Erro ao buscar as marcas:', error);
      }
    },
    startSlider() {
      const moveSlider = () => {
        // Verifique se a referência está definida antes de continuar
        if (this.$refs.listaDeMarcas) {
          if (!this.sliderPaused) {
            this.position -= this.scrollSpeed;
            this.$refs.listaDeMarcas.style.transform = `translateX(${this.position}px)`;

            if (Math.abs(this.position) >= this.$refs.listaDeMarcas.scrollWidth / 2) {
              this.position = 0;
            }
          }
          // Chama a próxima animação
          this.animationFrameId = requestAnimationFrame(moveSlider);
        } else {
          // Se a referência não estiver definida, pare a animação
          console.warn('A referência listaDeMarcas não está definida. A animação foi pausada.');
        }
      };

      // Inicialize a animação
      moveSlider();
    },
    pauseSlider() {
      this.sliderPaused = true;
    },
    resumeSlider() {
      this.sliderPaused = false;
    },
    selectBrand(marca) {
      this.$router.push({ name: 'marca', params: { brandSlug: marca.slug } });
    }
  }
};
</script>


<style scoped>
.slider-de-marcas {
  padding: 20px 0;
  overflow: hidden;
}

.lista-de-marcas {
  display: flex;
  justify-content: flex-start;
  gap: 30px; 
}

.marca-unica {
  flex: 0 0 200px; 
  max-width: 200px;
  transition: transform 0.3s ease;
  text-align: center;
  align-items: center;
  margin-top: 20px;
}

.marca-unica:hover {
  transform: scale(1.1);
}

.logo-container {
  width: 200px;
  height: 200px;
  border: 1px solid #c99c5a;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin: 0 auto;
  background-color: transparent;
  padding: 20px;
}

.logo-container img {
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
}

.sem-logo {
  color: #333;
  font-size: 18px;
  text-align: center;
  padding: 10px;
}

@media (max-width: 768px) {
  .marca-unica {
    flex: 0 0 150px;
    max-width: 150px;
  }

  .logo-container {
    width: 120px;
    height: 120px;
  }
}

@media (max-width: 480px) {
  .marca-unica {
    flex: 0 0 120px;
    max-width: 120px;
  }

  .logo-container {
    width: 100px;
    height: 100px;
  }
}

.linha-abaixo-titulo {
  height: 4px;
  background-color: #e0e0e0;
  border-radius: 2px;
  width: 100%;
}

h2 {
  font-size: 2rem; 
  color: #C99C5A; 
  font-weight: bold; 
  font-family: 'Lato', sans-serif;
}
</style>
