<template>
  <div class="product-page">
    <LoadingSpinner :isLoading="isLoading" />
    <div v-if="currentCategory && currentCategory.departments.length" class="category-sidebar">
      <div>
        <h3 style="font-size: 20px; padding: 10px">{{ currentCategory.name }}</h3>
        <ul class="department-list">
          <li v-for="(department, deptIndex) in currentCategory.departments" :key="deptIndex">
            <a href="#" class="department-link" @click.prevent="selectDepartment(department, currentCategory)"
              :class="{ 'selected-department': selectedDepartmentId === department.id }">
              {{ department.name }}
            </a>
          </li>
        </ul>
      </div>

      <div class="brand-filter">
        <h4 style="font-size: 20px; padding: 10px">{{ $t('categories.brands') }}</h4>
        <ul class="brand-list">
          <li v-for="(brand, brandIndex) in brands" :key="brandIndex" class="marca">
            <label>
              <input type="checkbox" :value="brand.id" :checked="selectedBrandIds.includes(brand.id)"
                @change="filterByBrand(brand.id)">
              <span class="check"></span>
              {{ brand.name }}
            </label>
          </li>
        </ul>
      </div>
    </div>

    <div class="product-list-section">
      <div class="header">
        <h2>{{ selectedTitle }}</h2>
        <div class="sort-options">

          <div class="custom-select-wrapper">
            <select id="sort" v-model="sortOption">
              <option value="">{{ $t('categories.sortOptions.placeholder') }}</option>
              <option value="valor_venda:asc">{{ $t('categories.sortOptions.priceLow') }}</option>
              <option value="valor_venda:desc">{{ $t('categories.sortOptions.priceHigh') }}</option>
              <option value="nome:asc">{{ $t('categories.sortOptions.nameAsc') }}</option>
              <option value="nome:desc">{{ $t('categories.sortOptions.nameDesc') }}</option>
            </select>
          </div>
        </div>
      </div>

      <div v-if="isLoading">
        <ProdutosSkeleton :isLoading="isLoading" />
      </div>
      <div v-else-if="productsLoaded && filteredProducts.length === 0">
        <p>{{ $t('categories.noProducts') }}</p>
      </div>

      <div class="product-list">
        <ProductCard v-for="(product, index) in sortedProducts" :key="index" :product="product"
          :currentPage="currentPage" :produtosAddCart="produtosAddCart" :defaultImage="defaultImage" />
      </div>

      <div class="pagination-container" v-if="totalPages > 1">
        <button @click="prevPage" :disabled="currentPage === 1" class="pagination-button">
          {{ $t('categories.pagination.previous') }}
        </button>
        <button @click="nextPage" :disabled="currentPage === totalPages" class="pagination-button">
          {{ $t('categories.pagination.next') }}
        </button>
      </div>
    </div>
  </div>
</template>


<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { fetchCategories, fetchBrands, fetchProducts } from '@/services/apiService';
import logo from "../assets/images/logo-branca.png"
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import ProductCard from '@/components/produtos/ProductCard.vue';

const { locale } = useI18n();
const categories = ref([]);
const filteredProducts = ref([]);
const selectedTitle = ref('Produtos');
const sortOption = ref('');
const defaultImage = require('../assets/images/produto-sem-imagem.jpg');
const error = ref(null);
const selectedCategoryId = ref(null);
const selectedBrand = ref(null);
const selectedDepartmentId = ref(null);
const searchText = ref('');
const currentPage = ref(1);
const totalPages = ref(1);
const totalProducts = ref(0);
const isLoading = ref(false);
const productsLoaded = ref(false);
const brands = ref([]);
const selectedBrandIds = ref([]);
const cart = ref([]);
const produtosAddCart = ref([]);
const externalId = ref(null);
const store = useStore();
const route = useRoute();
const router = useRouter();

const currentCategory = computed(() => {
  return categories.value.find(c => c.slug === route.params.categoriaSlug) || null;
});
const adicionarAoCarrinho = (product) => {
  if (product.tem_estoque) {
    store.commit('ecommerce/ADD_TO_CART', {
      ...product,
    });
  } else {
    alert('Este produto está indisponível no momento!');
  }
};



const goToPage = (page) => {
  router.push({
    name: 'produtos',
    params: {
      categoriaSlug: route.params.categoriaSlug,
      departamentoSlug: route.params.departamentoSlug
    },
    query: {
      ...route.query,
      page: page
    }
  });

  fetchProductsList(page);
};

const nextPage = () => {
  if (currentPage.value < totalPages.value && filteredProducts.value.length > 0) {
    goToPage(currentPage.value + 1);
  }
};

const prevPage = () => {
  if (currentPage.value > 1) {
    goToPage(currentPage.value - 1);
  }
};


const selectDepartment = async (department, category) => {
  selectedDepartmentId.value = department.id;
  selectedTitle.value = department.name;
  selectedBrandIds.value = [];

  router.push({
    name: 'produtos',
    params: {
      departamentoSlug: department.slug,
      categoriaSlug: category.slug,
    }
  });

  goToPage(1);
  await fetchProductsList(1);

  await fetchBrandsByDepartment(department.slug);
};

const fetchBrandsByDepartment = async (departmentSlug) => {
  try {
    isLoading.value = true;
    const response = await fetch(`${process.env.VUE_APP_API_URI}/api/public/departments/${departmentSlug}`);
    const data = await response.json();

    if (data.brands && data.brands.length) {
      brands.value = data.brands.map(brand => ({
        id: brand.id,
        name: brand.nome,
        slug: brand.slug
      })).sort((a, b) => a.name.localeCompare(b.name));
    } else {
      console.error("Nenhuma marca encontrada para o departamento");
    }
  } catch (error) {
    console.error("Erro ao buscar marcas", error);
  } finally {
    isLoading.value = false;
  }
};


const selectBrand = async (brand) => {
  if (selectedBrandIds.value.length === brands.value.length) {
    selectedBrandIds.value = [brand.id];
  } else {
    const index = selectedBrandIds.value.indexOf(brand.id);
    if (index > -1) {
      selectedBrandIds.value.splice(index, 1);
    } else {
      selectedBrandIds.value.push(brand.id);
    }
  }


  router.push({
    name: 'produtos',
    params: {
      categoriaSlug: route.params.categoriaSlug,
      departamentoSlug: route.params.departamentoSlug
    },
    query: {
      ...route.query,
      brandSlug: selectedBrandIds.value.join(',')
    }
  });

  goToPage(1);

  fetchProductsList(currentPage.value);
};


const sortedProducts = computed(() => {
  return filteredProducts.value.map(product => ({
    ...product,
    formattedPrice: `${product.moeda.simbolo} ${product.valor_venda}`,
  }));
});

const fetchCategoriesList = async () => {
  isLoading.value = true;
  try {
    const data = await fetchCategories();
    const sortedCategories = data.sort((a, b) => a.name.localeCompare(b.name));

    sortedCategories.forEach(category => {
      if (category.departments) {
        category.departments.sort((a, b) => a.name.localeCompare(b.name));
      }
    });

    categories.value = sortedCategories;
  } catch (err) {
    error.value = 'Erro ao buscar categorias e departamentos.';
    console.error(error.value, err);
  } finally {
    isLoading.value = false;
  }
};

const fetchBrandsList = async () => {
  isLoading.value = true;
  try {
    const data = await fetchBrands();
    brands.value = data.sort((a, b) => a.name.localeCompare(b.name));

    selectedBrandIds.value = brands.value.map(brand => brand.id);
  } catch (err) {
    error.value = 'Erro ao buscar marcas.';
    console.error(error.value, err);
  } finally {
    isLoading.value = false;
  }
};


const filterByBrand = (brandId) => {
  const index = selectedBrandIds.value.indexOf(brandId);
  if (index > -1) {
    selectedBrandIds.value.splice(index, 1);
  } else {
    selectedBrandIds.value.push(brandId);
  }

  router.push({
    name: 'produtos',
    params: {
      categoriaSlug: route.params.categoriaSlug,
      departamentoSlug: route.params.departamentoSlug
    },
    query: {
      ...route.query,
      brandSlug: selectedBrandIds.value.join(',')
    }
  });

  fetchProductsList(currentPage.value);
};

const fetchProductsList = async (page = 1) => {
  currentPage.value = page;
  isLoading.value = true;
  productsLoaded.value = false;
  window.scrollTo(0, 0);

  try {
    filteredProducts.value = [];

    let orderBy = 'id';
    let order = 'desc';

    if (sortOption.value && sortOption.value.includes(':')) {
      const [sortField, sortOrder] = sortOption.value.split(':');
      orderBy = sortField;
      order = sortOrder;
    }

    const params = {
      text: route.query.text || searchText.value,
      category_id: selectedCategoryId.value,
      department_id: selectedDepartmentId.value,
      brands_ids: selectedBrandIds.value.length ? selectedBrandIds.value.join(',') : null,
      page: currentPage.value,
      order_by: `tem_estoque,${orderBy}`,
      order: `desc,${order}`,
      all_products: 1
    };

    const data = await fetchProducts(params);

    filteredProducts.value = data.data || [];
    totalProducts.value = data.total;
    totalPages.value = Math.ceil(totalProducts.value / 20);

    store.commit('setMoedaId', data.moedaId);
    store.commit('setLocale', data.locale);

  } catch (err) {
    error.value = 'Erro ao buscar produtos.';
    console.error(error.value, err);
  } finally {
    isLoading.value = false;
    productsLoaded.value = true;
  }
};


const fetchBrandsByCategory = async (categorySlug) => {
  try {
    isLoading.value = true;
    const response = await fetch(`${process.env.VUE_APP_API_URI}/api/public/categories/${categorySlug}`);
    const data = await response.json();


    if (data.brands && data.brands.length) {
      brands.value = data.brands.map(brand => ({
        id: brand.id,
        name: brand.nome,
        slug: brand.slug
      })).sort((a, b) => a.name.localeCompare(b.name));
    } else {
      console.error("Nenhuma marca encontrada para a categoria");
    }
  } catch (error) {
    console.error("Erro ao buscar marcas", error);
  } finally {
    isLoading.value = false;
  }
};

watch(currentCategory, async (newCategory) => {
  if (newCategory) {
    await fetchBrandsByCategory(newCategory.slug);
  }
});

watch([() => route.params.departamentoSlug, () => route.params.categoriaSlug, () => route.query.page, () => route.query.text],
  async ([departamentoSlug, categoriaSlug, page, text], [oldDepartamentoSlug, oldCategoriaSlug, oldPage, oldText]) => {
    if (categoriaSlug !== oldCategoriaSlug) {
      const categoryItem = categories.value.find(c => c.slug === categoriaSlug);
      selectedCategoryId.value = categoryItem ? categoryItem.id : null;
      selectedTitle.value = categoryItem ? categoryItem.name : 'Produtos';
    }

    if (departamentoSlug !== oldDepartamentoSlug) {
      const departmentItem = currentCategory.value?.departments.find(d => d.slug === departamentoSlug);
      selectedDepartmentId.value = departmentItem ? departmentItem.id : null;
      selectedTitle.value = departmentItem ? departmentItem.name : selectedTitle.value;
    }

    currentPage.value = parseInt(page) || 1;
    searchText.value = text || '';
    await fetchProductsList(currentPage.value);
  });

watch(sortOption, () => {
  fetchProductsList(currentPage.value);
});

onMounted(async () => {
  currentPage.value = parseInt(sessionStorage.getItem('currentPage')) || 1;

  await fetchCategoriesList();
  await fetchBrandsList();

  if (route.params.categoriaSlug) {
    const categoryItem = categories.value.find(c => c.slug === route.params.categoriaSlug);
    selectedCategoryId.value = categoryItem ? categoryItem.id : null;
    selectedTitle.value = categoryItem ? categoryItem.name : 'Produtos';
  }

  if (route.params.departamentoSlug && currentCategory.value) {
    const departmentItem = currentCategory.value.departments.find(d => d.slug === route.params.departamentoSlug);
    selectedDepartmentId.value = departmentItem ? departmentItem.id : null;
    selectedTitle.value = departmentItem ? departmentItem.name : selectedTitle.value;
  }
  if (route.query.brandSlug) {
    selectedBrandIds.value = route.query.brandSlug.split(',').map(slug => parseInt(slug));
  }
  await fetchProductsList(currentPage.value);
});


watch(currentPage, (newPage) => {
  sessionStorage.setItem('currentPage', newPage);
});

const visiblePages = computed(() => {
  let pages = [];
  if (totalPages.value <= 5) {
    pages = Array.from({ length: totalPages.value }, (_, i) => i + 1);
  } else {
    const start = Math.max(1, currentPage.value - 2);
    const end = Math.min(totalPages.value, start + 4);

    pages = Array.from({ length: end - start + 1 }, (_, i) => start + i);
  }
  return pages;
});

</script>



<style scoped>
html,
body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

.brand-filter {
  margin: 20px 0;
}

.brand-list {
  list-style: none;
  padding: 0;
  margin-left: 20px;
}

.marca {
  margin-bottom: 10px;
  position: relative;
}

label {
  cursor: pointer;
  display: flex;
  align-items: center;
}

input[type="checkbox"] {
  display: none;
}

.check {
  width: 15px;
  height: 15px;
  border: 2px solid #C99C5A;
  border-radius: 4px;
  margin-right: 4px;
  position: relative;
}

.check:after {
  content: '';
  position: absolute;
  top: 1px;
  left: 4px;
  width: 4px;
  height: 8px;
  border: solid black;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  display: none;
}

input[type="checkbox"]:checked+.check:after {
  display: block;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
}

.selected-department {
  font-weight: bold;
  color: #ffffff;
  background-color: #C99C5A;

  border-radius: 4px;
  transition: background-color 0.3s, color 0.3s;
}

.department-link.selected-department {
  color: #ffffff;
  font-size: 14px;
}

.product-page {
  display: flex;
  padding: 20px;
  font-family: 'Roboto', sans-serif;
  max-width: 1500px;
  margin: 0 auto;

}


.category-name {
  color: black
}

.category-sidebar {
  width: 280px;
  padding-top: 20px;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid #e0e0e0;
  position: relative;
}

.category-list {
  list-style: none;
  padding: 0;
  margin: 0;
  flex: 1;
}

.category-item {
  position: relative;
  margin-bottom: 12px;
}

.category-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  color: #333;
  text-decoration: none;
  border-radius: 8px;
  background: #ffffff;
  border: 1px solid transparent;
  transition: background 0.3s ease, color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;
  font-weight: 600;
}

.category-link:hover,
.category-link.active {
  background-color: #C99C5A;
  color: #ffffff;
  border-color: #C99C5A;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.department-list {
  list-style: none;
  padding: 0;
  margin: 0;
  border-radius: 8px;
  width: 100%;
  margin-top: 8px;

}

.department-link {
  display: block;
  padding: 10px 16px;
  color: #333;
  text-decoration: none;
  border-bottom: 1px solid #f0f0f0;
  transition: background 0.3s ease;
}

.department-link:hover {
  background: #C99C5A;

}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.category-link i {
  transition: transform 0.3s ease;
}

.product-list-section {
  flex: 1;
  padding: 20px;
  max-width: 100vw;
  box-sizing: border-box;
}


.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.product-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  box-sizing: border-box;
}


.product-item {
  border: 2px solid #e0ccad;
  border-radius: 12px;
  width: 100%;
  max-width: 260px;
  min-width: 260px;
  flex: 1;
  height: auto;
  aspect-ratio: 4 / 7;
  overflow: hidden;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background-color: white;
}

.product-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.product-image-container {
  width: 100%;
  height: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.out-of-stock-tag {
  color: red;
  font-weight: bold;
  background-color: #f8d7da;
  padding: 3px 6px;
  border-radius: 3px;
  display: inline-block;
  margin-top: 5px;
}

.product-image {
  width: auto;
  height: 100%;
  object-fit: cover;
  padding-top: 15px;
  max-width: 100%;
  box-sizing: border-box;
}

.product-info {
  height: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.product-name {
  font-size: 18px;
  font-family: 'Lato', sans-serif;
  color: rgb(43, 43, 43);
  margin-bottom: 10px;
  font-weight: 500;
  line-height: 1.5;
  margin-top: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}


.product-price {
  font-size: 28px;
  font-family: 'Lato', sans-serif;
  color: #c99c5a;
  margin: 3px 0;
  font-weight: 700;
  margin-top: 10px;
}

.product-code {
  font-size: 16px;
  color: #757575;
  line-height: 1.667;
  margin-top: 15px;
  margin-bottom: 25px;
}

@media (max-width: 768px) {
  .product-list {
    flex-direction: column;
    align-items: center;

  }

  .product-item {
    width: 100%;
    max-width: 300px;
    margin-bottom: 20px;
  }
}

@media (min-width: 769px) and (max-width: 1200px) {
  .product-item {
    width: 45%;
    max-width: 260px;
  }
}

@media (min-width: 1201px) {
  .product-item {
    width: 22%;
    max-width: 260px;
  }
}

.linha-abaixo-titulo {
  height: 4px;
  background-color: #e0e0e0;
  border-radius: 2px;
  width: 100%;
  margin-bottom: 20px;
}

h2 {
  font-size: 2rem;
  color: #C99C5A;
  font-weight: bold;
  font-family: 'Lato', sans-serif;
}

.sort-options {
  display: flex;
  align-items: center;
  gap: 15px;
}

.sort-options select {
  padding: 8px 24px;
  font-size: 16px;
  border-radius: 50px;
  border: 1px solid transparent;
  background-color: #707070;
  color: #e7c084;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  appearance: none;
  cursor: pointer;
  transition: border-color 0.3s, box-shadow 0.3s, background-color 0.3s;
}

.sort-options select:hover {
  background-color: #5a5a5a;
  border-color: #555;
}

.sort-options select:focus {
  outline: none;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
}

.print-button-container .print-button {
  background-color: transparent;
  border: 1px solid #ccc;
  color: #333;
  padding: 8px 16px;
  font-size: 14px;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
}

.print-button-container .print-button:hover {
  background-color: #f4f4f4;
  border-color: #888;
  color: #000;
}

.print-button-container .print-button:focus {
  outline: none;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
}

.print-button-container .print-button:active {
  background-color: #e0e0e0;
  border-color: #707070;
  color: #333;
}

.print-button-container .print-button i {
  margin-right: 8px;
}


.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  width: 100%;
  padding: 10px;
  position: relative;
}

.pagination-button {
  padding: 10px 20px;
  margin: 0 5px;
  border: none;
  border-radius: 50px;
  background: linear-gradient(145deg, #C99C5A, #C4A77D);
  color: white;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background 0.3s, transform 0.2s;
}

.pagination-button:hover {
  background: linear-gradient(145deg, #C4A77D, #C99C5A);
  transform: scale(1.05);
}

.pagination-button:disabled {
  background: #E0D8C1;
  cursor: not-allowed;
  box-shadow: none;
}

.pagination-numbers {
  display: flex;
  align-items: center;
  margin: 0 10px;
}

.pagination-numbers button {
  padding: 10px 15px;
  margin: 0 5px;
  border: 1px solid transparent;
  border-radius: 50px;
  background: #fff;
  color: #C99C5A;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: background 0.3s, color 0.3s;
}

.pagination-numbers button.active {
  background: #C99C5A;
  color: #fff;
}

.pagination-numbers button:hover {
  background: #C99C5A;
  color: #fff;
}

@media (max-width: 768px) {
  .category-sidebar {
    display: none;
  }

  .product-page {
    padding: 1rem;
  }

  .print-button-container .print-button {
    display: none;
  }

  .pagination-controls {
    flex-direction: column;
  }

  .pagination-controls button {
    min-width: auto;

  }

  .pagination-numbers {
    display: none;
  }

  .pagination-button {
    font-size: 14px;
    padding: 8px 16px;
  }
}


.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-left-color: #c99c5a;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}


.add-to-cart-overlay {
  background-color: #C99C5A;
  /* Cor de fundo personalizada */
  color: white;
  /* Texto branco */
  border: none;
  /* Remove bordas padrão */
  padding: 12px 30px;
  /* Tamanho do botão */
  font-size: 16px;
  /* Tamanho da fonte */
  font-weight: bold;
  /* Texto em negrito */
  border-radius: 30px;
  /* Bordas arredondadas */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  /* Cursor de clique */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  /* Sombra para destacar o botão */
  transition: background-color 0.3s ease, transform 0.3s ease;
  /* Suaviza animações */
}

.add-to-cart-overlay:hover {
  background-color: #b2884d;
  /* Cor mais escura ao passar o mouse */
  transform: scale(1.05);
  /* Leve aumento no tamanho para feedback visual */
}

.add-to-cart-overlay i {
  margin-right: 10px;
  /* Espaçamento entre o ícone e o texto */
  font-size: 18px;
  /* Tamanho do ícone */
}

.add-to-cart-btn,
.added-to-cart {
  background-color: #C99C5A;
  /* Cor de fundo personalizada */
  color: white;
  /* Cor do texto */
  border: none;
  /* Remove borda */
  padding: 8px 16px;
  /* Menos espaço interno (reduzindo o tamanho) */
  font-size: 12px;
  /* Reduz o tamanho da fonte */
  font-weight: bold;
  /* Texto em negrito */
  border-radius: 10px;
  /* Bordas arredondadas */
  cursor: pointer;
  /* Cursor de clique */
  display: inline-flex;
  /* Alinha o texto com o ícone */
  align-items: center;
  /* Centraliza o conteúdo */
  justify-content: center;
  /* Garante que o conteúdo esteja centralizado */
  transition: background-color 0.3s ease, transform 0.3s ease;
  /* Animações suaves */
}

.add-to-cart-btn:hover,
.added-to-cart:hover {
  background-color: #b2884d;
  /* Cor mais escura ao passar o mouse */
  transform: scale(1.05);
  /* Aumenta o botão ligeiramente */
}

.add-to-cart-btn i,
.added-to-cart i {
  margin-right: 6px;
  /* Reduz o espaço entre o ícone e o texto */
  font-size: 14px;
  /* Ajuste o tamanho do ícone */
}
</style>
