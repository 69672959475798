<template>
  <div class="header-top-nav">
    <div class="container">
      <div class="row align-items-center justify-content-between">
        <!-- Currency Exchange Rates -->
        <div class="col-lg-8 col-md-6 col-sm-12 d-flex align-items-center">
          <div class="exchange-rates">
            <div class="rate" v-for="(exchangeRate, currency) in exchangeRates" :key="currency">
              <img :src="exchangeRate.flag" alt="" class="flag-icon">
              <span class="rate-value">
                {{ exchangeRate.rate ? formatRate(exchangeRate.rate, currency) : '---' }}
              </span>
            </div>
          </div>
        </div>

        <!-- Sales Team and Language Selector -->
        <div class="col-lg-4 col-md-6 col-sm-12 d-flex justify-content-end align-items-center ms-auto">
          <!-- Sales Team Button -->
          <a href="/vendedores" class="sales-team-button">
            <i class="fab fa-whatsapp sales-team-icon"></i>
            <span class="sales-team-text">{{ $t('sales_team') }}</span>
          </a>

          <!-- Language Selector -->
          <div class="language-selector">
            <button @click="toggleDropdown" class="language-button">
              <img
                :src="currentLanguage === 'Português' ? require('@/assets/images/bandeira-brasil.webp') : require('@/assets/images/bandeira-paraguai.webp')"
                alt="Flag" class="flag-icon" />
              {{ currentLanguage }} <i class="fas fa-chevron-down"></i>
            </button>
            <div v-if="dropdownVisible" class="dropdown-menu active">
              <button @click="changeLanguage('pt-BR')" class="dropdown-item">
                <img src="@/assets/images/bandeira-brasil.webp" alt="Portuguese Flag" class="flag-icon" />
                Português
              </button>
              <button @click="changeLanguage('es-PY')" class="dropdown-item">
                <img src="@/assets/images/bandeira-paraguai.webp" alt="Spanish Flag" class="flag-icon" />
                Español
              </button>
            </div>
          </div>
        </div>


        <!-- Login e Cart Section (Exibir em telas menores e esconder em telas maiores que 991px) -->
        <div class="col-lg-4 col-md-6 col-sm-12 d-flex justify-content-end align-items-center mt-2">
          <a v-if="!isAuthenticated" href="/login" class="login-button d-flex me-3 d-flex d-lg-none">
            <div class="login-icon-wrapper">
              <i class="fas fa-user login-icon"></i>
            </div>
            <span class="login-text">{{ $t('login') }}</span>
          </a>
          <a v-if="isAuthenticated" href="/perfil" class="login-button d-flex me-3 d-flex d-lg-none">
            <div class="login-icon-wrapper">
              <i class="fas fa-user login-icon"></i>
            </div>
            <span class="login-text">{{ $t('profile') }}</span>
          </a>
          <!-- Cart Button -->
          <a @click="openCart" class="cart-button d-flex d-flex d-lg-none">
            <div class="cart-icon-wrapper">
              <i class="fas fa-shopping-cart cart-icon"></i>
            </div>
            <span class="cart-text">{{ $t('cart.title') }}</span>
          </a>
        </div>



      </div>
    </div>
  </div>
</template>


<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import { eventBus } from '@/eventBus';  // Corrigido para importar a instância



export default {
  name: 'HeaderTopNav',
  data() {
    return {
      exchangeRates: {
        'R$': { rate: null, flag: require('@/assets/images/bandeira-brasil.webp') },
        'G$': { rate: null, flag: require('@/assets/images/bandeira-paraguai.webp') }
      },
      dropdownVisible: false,
      windowWidth: window.innerWidth,
      currentLanguage: this.$i18n.locale === 'pt-BR' ? 'Português' : 'Español',
      confirmLogout: false,
    };
  },
  computed: {
    ...mapGetters('auth', ['isAuthenticated']),
  },
  methods: {
    openCart() {
      eventBus.emit('toggle-cart');
    },
    async fetchExchangeRates() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URI}/api/public/exchange-rates`);
        const data = response.data;

        data.forEach(rate => {
          const sourceCurrency = rate.source_currency.symbol;
          const targetCurrency = rate.target_currency.symbol;

          if (sourceCurrency === 'U$' && targetCurrency === 'R$') {
            this.exchangeRates['R$'].rate = parseFloat(rate.tax).toFixed(rate.target_currency.decimals);
          } else if (sourceCurrency === 'U$' && targetCurrency === 'G$') {
            this.exchangeRates['G$'].rate = parseFloat(rate.tax).toFixed(rate.target_currency.decimals);
          }
        });
      } catch (error) {
        console.error('Failed to fetch exchange rates:', error);
      }
    },
    formatRate(rate, currency) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency === 'R$' ? 'BRL' : 'PYG',
      });
      return formatter.format(rate);
    },
    toggleDropdown() {
      this.dropdownVisible = !this.dropdownVisible;
    },
   
    changeLanguage(language) {
      this.$store.dispatch('language/changeLanguage', language);
      localStorage.setItem('locale', language);
      this.$i18n.locale = language;
      this.dropdownVisible = false;
      this.$store.dispatch('ecommerce/getCart');
      window.location.reload();
    },

    handleResize() {
      this.windowWidth = window.innerWidth;
    }
  },
  watch: {
    '$i18n.locale'(newLocale) {
      if (newLocale === 'pt-BR') {
        this.currentLanguage = 'Português';
      } else if (newLocale === 'es-PY') {
        this.currentLanguage = 'Español';
      }
    }
  },
  created() {
    const storedLanguage = localStorage.getItem('locale');
    if (storedLanguage) {
      this.$i18n.locale = storedLanguage;
    } else {
      this.$i18n.locale = this.$store.state.language.currentLanguage;
    }
  },
  mounted() {
    this.fetchExchangeRates();
    if (this.isAuthenticated) {
      this.$store.dispatch('auth/fetchUser');
    }
  }
}
</script>


<style scoped>


.language-button {
  background-color: #C99C5A;
  color: #fff;
  border: none;
  padding: 5px 10px;
  font-size: 1.1em;
  cursor: pointer;
  border-radius: 50px;
  display: flex;
  align-items: center;
}

.language-button:hover {
  background-color: #333;
}

.fas {
  margin-left: 8px;
}

.logout-button {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.logout-icon {
  margin-right: 8px;
  font-size: 1.1rem;
}

.logout-text {
  font-size: 1rem;
}

.logout-button:hover {
  color: red;
}

.login-icon-wrapper,
.cart-icon-wrapper {
  width: 40px;
  height: 40px;
  background-color: #C99C5A;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-icon,
.cart-icon {
  color: white;
  font-size: 18px;
}


.login-button:hover .login-icon-wrapper {
  background-color: #b48851;
}

.cart-button {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #777777;
  font-size: 14px;
  font-weight: 600;
  margin-left: 20px;
}


.cart-button:hover .cart-icon-wrapper {
  background-color: #b48851;
}


.cart-button:hover .cart-text {
  color: #b48851;
}

.dropdown-menu {
  position: absolute;
  background-color: #C99C5A;
  color: #fff;
  min-width: 150px;
  top: 100%;
  left: 0;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: none;
}

.dropdown-menu.active {
  display: block;
}

.col-lg-4 .d-flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.login-button,
.cart-button {
  margin-right: 15px;
}

.cart-button:last-child {
  margin-right: 0;
}

@media (max-width: 576px) {
  .col-lg-4 .d-flex {
    justify-content: center;
  }
}

.dropdown-item {
  background-color: #C99C5A;
  color: #fff;
  padding: 10px;
  border: none;
  text-align: left;
  width: 100%;
  cursor: pointer;
  font-size: 1em;
}

.dropdown-item:hover {
  background-color: #333;
}

.flag-icon {
  width: 25px;
  height: auto;
  margin-right: 10px;
}

.exchange-rates .rate {
  display: inline-block;
  margin-right: 20px;
}

.header-top-nav {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding-bottom: 0;
  min-width: 100%;
}

.header-top-nav .col-lg-8,
.header-top-nav .col-lg-4 {
  flex: 1;
}


.exchange-rates {
  display: flex;
  font-size: 0.9rem;
  white-space: nowrap;
}

.rate {
  display: flex;
  align-items: center;
  margin-left: 20px;
}



.sales-team-icon {
  margin-right: 8px;
  font-size: 0.9rem;
}

.language-selector,
.sales-team-button {
  position: relative;
  display: inline-block;
}

.language-selector {
  position: relative;
  display: inline-block;
  margin-left: 15px;
}

.sales-team-button {
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.sales-team-text {
  font-size: 0.9rem;
}

@media (max-width: 991px) {
  .language-selector, .sales-team-button {
    margin-left: 10px; 
  }
}

.flag-icon {
  margin-right: 5px;
  width: 16px;
  height: 11px;
}

@media (max-width: 991px) {
  .sales-team-text {
    font-size: 0.8rem;
  }
}

@media (max-width: 576px) {
  .sales-team-text {
    font-size: 0.7rem;
  }
}

@media (min-width: 992px) {
  .hide-lg {
    display: none !important;
  }
}

</style>
