import axios from 'axios';

export default {
  state() {
    return {
      routeHistory: [],
      categories: JSON.parse(localStorage.getItem('categories')) || [],
    };
  },
  mutations: {
    ADD_ROUTE(state, route) {
      state.routeHistory.push(route);
    },
    CLEAR_HISTORY(state) {
      state.routeHistory = [];
    },
    SET_CATEGORIES(state, categories) {
      state.categories = categories;
      localStorage.setItem('categories', JSON.stringify(categories)); 
    },
  },
  actions: {
    addRoute({ commit }, route) {
      commit('ADD_ROUTE', route);
    },
    clearHistory({ commit }) {
      commit('CLEAR_HISTORY');
    },
    async fetchCategories({ commit }) {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URI}/api/public/categories`);
        commit('SET_CATEGORIES', response.data);
      } catch (error) {
      }
    },
  },
  getters: {
    getCategories(state) {
      return state.categories;
    },
  },
};
