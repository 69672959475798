<template>
  <div class="settings-page">
    <div class="settings-container">
      <div class="settings-section">
        <h1><i class="fas fa-key"></i> {{ $t('changePassword') }}</h1>
        <p>{{ $t('updatePasswordMessage') }}</p>

        <form @submit.prevent="changePassword" class="change-password-form">
          <div class="form-group">
            <label for="password_currently">
              <i class="fas fa-lock"></i> {{ $t('currentPassword') }}
            </label>
            <input 
              type="password" 
              v-model="passwordCurrently" 
              id="password_currently" 
              :placeholder="$t('enterCurrentPassword')" 
              required 
            />
          </div>
          <div class="form-group">
            <label for="new_password">
              <i class="fas fa-lock"></i> {{ $t('newPassword') }}
            </label>
            <input 
              type="password" 
              v-model="newPassword" 
              id="new_password" 
              :placeholder="$t('enterNewPassword')" 
              required 
            />
          </div>
          <div class="form-group">
            <label for="confirm_password">
              <i class="fas fa-lock"></i> {{ $t('confirmNewPassword') }}
            </label>
            <input 
              type="password" 
              v-model="confirmPassword" 
              id="confirm_password" 
              :placeholder="$t('confirmNewPassword')" 
              required 
            />
          </div>
          <div class="form-actions">
            <button type="submit" class="btn-submit">
              <i class="fas fa-save"></i> {{ $t('saveChanges') }}
            </button>
          </div>
        </form>

        <div v-if="errorMessages.length" class="error-messages">
          <ul>
            <li v-for="(message, index) in errorMessages" :key="index">
              <i class="fas fa-exclamation-circle"></i> {{ message }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
  name: 'SettingsPage',
  data() {
    return {
      passwordCurrently: '',
      newPassword: '',
      confirmPassword: '',
      errorMessages: [],
    };
  },
  computed: {
    ...mapGetters(['getToken']),
  },
  methods: {
    async changePassword() {
      this.errorMessages = [];

      if (this.newPassword !== this.confirmPassword) {
        this.errorMessages.push(this.$t('passwordMismatchError'));
        return;
      }

      try {
        await axios.post(
          `${process.env.VUE_APP_API_URI}/ecom/cliente/trocar-senha-l`,
          {
            password_currently: this.passwordCurrently,
            password: this.newPassword,
            password_confirmation: this.confirmPassword,
          },
          {
            headers: {
              Authorization: `Bearer ${this.getToken}`,
            },
          }
        );

        alert(this.$t('passwordChangeSuccess'));
        this.passwordCurrently = '';
        this.newPassword = '';
        this.confirmPassword = '';
      } catch (error) {
        console.error(this.$t('passwordChangeError'), error);
        this.errorMessages.push(this.$t('passwordChangeError'));
      }
    },
    
  },
};
</script>

  
  <style scoped>
.settings-page {
  display: flex;
  width: 100%;
  justify-content: center;
}

.settings-container {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  
}

.settings-container h1,
.settings-section h2 {
  color: #34495e;
  margin-bottom: 20px;
}

.change-password-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.form-group label {
  font-weight: bold;
  color: #34495e;
}

.form-group input {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-group input:focus {
  outline: none;
  border-color: #2980b9;
}

.form-actions {
  text-align: right;
}

.btn-submit {
  background-color: #27ae60;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.btn-submit:hover {
  background-color: #2ecc71;
}

.error-messages {
  margin-top: 20px;
  background-color: #f2dede;
  color: #a94442;
  border: 1px solid #ebccd1;
  padding: 10px;
  border-radius: 5px;
}

.error-messages ul {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

.error-messages li {
  margin-bottom: 5px;
}
</style>