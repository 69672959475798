<template>
  <div class="profile-container">
    <h1><i class="fas fa-id-card"></i> {{ $t('profileInformation') }}</h1>

    <!-- Loading Indicator -->
    <div v-if="isLoading" class="loading">
      <p>{{ $t('admin.loading_message') }}</p>
    </div>

    <div v-if="user && !isLoading" class="profile-details">
      <div class="detail-row">
        <strong><i class="fas fa-user"></i> {{ $t('fullName') }}</strong>
        <span>{{ user.nome || $t('notProvided') }}</span>
      </div>
      <div class="detail-row">
        <strong><i class="fas fa-id-badge"></i> {{ $t('document') }}</strong>
        <span>
          <span class="document-type">{{ user.documento_tipo }}: </span>
          {{ user.documento }}
        </span>
      </div>
      <div class="detail-row">
        <strong><i class="fas fa-envelope"></i> {{ $t('email') }}</strong>
        <span>{{ user.email || $t('notProvided') }}</span>
        <div v-if="!user.email_verified_at" class="email-not-confirmed">
          <p>{{ $t('verifyInbox') }}</p>
          <router-link to="/ecom/cliente/reenviar-email">
            <i class="fas fa-paper-plane"></i> {{ $t('resendEmail') }}
          </router-link>
        </div>
      </div>
      <div class="detail-row">
        <strong><i class="fas fa-passport"></i> {{ $t('foreigner') }}</strong>
        <span>{{ user.estrangeiro ? $t('yes') : $t('no') }}</span>
      </div>
      <div class="detail-row">
        <strong><i class="fas fa-phone"></i> {{ $t('phone') }}</strong>
        <span>{{ user.telefone || $t('notProvided') }}</span>
      </div>
      <div class="detail-row">
        <strong><i class="fas fa-clock"></i> {{ $t('lastLogin') }}</strong>
        <span>{{ formatDate(user.data_ultimo_login) || $t('notProvided') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Profile',
  data() {
    return {
      isLoading: true,  // Added loading state
    };
  },
  computed: {
    user() {
      // Simulate a loading delay before returning user data
      this.isLoading = !this.$store.getters['auth/getUser'];  // Check if user data is loaded
      return this.$store.getters['auth/getUser'];
    }
  },
  methods: {
    formatDate(date) {
      if (!date) return '';
      const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
      return new Date(date).toLocaleDateString('pt-BR', options);
    }
  },
};
</script>
<style scoped>
.profile-container {
  background: #f9f9f9;
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.profile-container h1 {
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
  color: #2c3e50;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border-bottom: 2px solid #e0e0e0;
  padding-bottom: 0.5rem;
}

.profile-details {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.detail-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
  padding: 0.8rem 0;
}

.detail-row strong {
  font-size: 1rem;
  color: #34495e;
}

.detail-row span {
  font-size: 0.95rem;
  color: #7f8c8d;
}

.email-not-confirmed {
  color: #e74c3c;
  font-size: 0.9rem;
  margin-top: 0.5rem;
}

.email-not-confirmed a {
  color: #e74c3c;
  text-decoration: none;
  font-weight: bold;
  display: inline-flex;
  align-items: center;
  gap: 0.3rem;
}

.email-not-confirmed a:hover {
  text-decoration: underline;
}

/* Loading styles */
.loading {
  text-align: center;
  color: #3498db;
  font-size: 1.5rem;
  margin-top: 2rem;
}

/* Mobile responsive styles */
@media (max-width: 768px) {
  .detail-row {
    flex-direction: column;
    align-items: flex-start;
  }
}
</style>
