<template>
  <div class="password-recovery-container">
    <h1>{{ $t('passwordRecovery') }}</h1>

    <!-- Formulário de Recuperação -->
    <form v-if="!isSubmitted" @submit.prevent="submitForm">
      <div class="form-group">
        <label for="email">{{ $t('email') }}</label>
        <input 
          type="email" 
          id="email" 
          v-model="form.email" 
          :placeholder="$t('enterEmail')" 
          required 
        />
      </div>
      <button type="submit" :disabled="isSubmitting">
        {{ isSubmitting ? $t('sending') : $t('sendRecoveryLink') }}
      </button>
    </form>

    <!-- Mensagem de Sucesso -->
    <div v-if="isSubmitted" class="success-message">
      <p>{{ $t('recoveryLinkSent') }}</p>
    </div>

    <!-- Links extras -->
    <div class="extra-actions">
      <p>
        {{ $t('rememberPassword') }} <router-link to="/login">{{ $t('backToLogin') }}</router-link>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        email: '',
      },
      isSubmitting: false,
      isSubmitted: false, 
    };
  },
  methods: {
    async submitForm() {
      if (!this.form.email) {
        alert(this.$t('enterEmail'));
        return;
      }

      this.isSubmitting = true;

      try {
        const response = await fetch(`${process.env.VUE_APP_API_URI}/ecom/cliente/recuperar-senha`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(this.form),
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || this.$t('errorSendingLink'));
        }

        this.isSubmitted = true;
        this.form.email = '';
      } catch (error) {
        alert(`${this.$t('error')}: ${error.message}`);
        console.error('Erro na recuperação de senha:', error.message);
      } finally {
        this.isSubmitting = false;
      }
    },
    changeLanguage(locale) {
      this.$i18n.locale = locale;
    },
  },
};
</script>

  <style scoped>
  .password-recovery-container {
    max-width: 400px;
    margin: 50px auto;
    padding: 30px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    width: 100%;
  }
  
  h1 {
    text-align: center;
    color: #3a3a3a;
    font-family: 'Roboto', sans-serif;
    font-size: 28px;
    margin-bottom: 30px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    font-size: 14px;
    color: #444;
    font-weight: 600;
    margin-bottom: 8px;
  }
  
  input {
    width: 100%;
    padding: 12px;
    border-radius: 8px;
    border: 1px solid #ccc;
    font-size: 16px;
    transition: all 0.3s ease;
  }
  
  input:focus {
    border-color: #a6d0f2;
    box-shadow: 0 0 8px rgba(0, 160, 255, 0.5);
    outline: none;
  }
  
  button {
    width: 100%;
    padding: 15px;
    background-color: #C99C5A; /* Cor do botão ajustada */
    color: #fff;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 600;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #b08050;
  }
  
  button:active {
    background-color: #9e6e39;
  }
  
  .success-message {
    background-color: #e0f7e0;
    padding: 15px;
    border: 1px solid #c1e6c1;
    border-radius: 4px;
    color: #2e7d32;
    font-size: 16px;
    text-align: center;
  }
  
  .extra-actions {
    text-align: center;
    margin-top: 20px;
  }
  
  .extra-actions p {
    margin: 5px 0;
  }
  
  router-link {
    color: #C99C5A;
    text-decoration: none;
  }
  
  router-link:hover {
    text-decoration: underline;
  }
  </style>
  