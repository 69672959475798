<template>
  <div class="login-container">
    <h1>{{ $t('login') }}</h1>
    <form @submit.prevent="submitForm">
      <div class="form-group">
        <div class="input-container">
          <label for="email" :class="{ 'active': form.email || focused.email }">E-mail</label>
          <input type="email" id="email" v-model="form.email" @focus="focused.email = true"
            @blur="focused.email = false" required />
        </div>
      </div>
      <div class="form-group">
        <div class="input-container">
          <label for="password" :class="{ 'active': form.password || focused.password }">Senha</label>
          <input type="password" id="password" v-model="form.password" @focus="focused.password = true"
            @blur="focused.password = false" required />
        </div>
      </div>
      <button type="submit" :disabled="isSubmitting">
        <span v-if="isSubmitting">{{ $t('loading') }}</span>
        <span v-else>{{ $t('login') }}</span>
      </button>
    </form>

    <!-- Mensagem de sucesso ou erro -->
    <div v-if="statusMessage" class="status-message">
      <p :class="{ 'success': isSuccess, 'error': !isSuccess }">{{ statusMessage }}</p>
    </div>

    <div class="extra-actions">
      <p>
        {{ $t('dontHaveAccount') }} <router-link to="/cadastro">{{ $t('register') }}</router-link>
      </p>
      <p>
        {{ $t('forgotPassword') }} <router-link to="/recuperar-senha">{{ $t('recover') }}</router-link>
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      form: {
        email: '',
        password: '',
      },
      focused: {
        email: false,
        password: false,
      },
      isSubmitting: false,
      statusMessage: '',
      isSuccess: false,
    };
  },
  methods: {
  async submitForm() {
    if (!this.form.email || !this.form.password) {
      this.statusMessage = this.$t('pleaseFillAllFields');
      this.isSuccess = false;
      return;
    }

    this.isSubmitting = true;
    this.statusMessage = '';

    try {
      const response = await fetch(`${process.env.VUE_APP_API_URI}/ecom/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(this.form),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || this.$t('errorMessage'));
      }

      const data = await response.json();

      // Salva o token e usuário na store
      this.$store.dispatch('auth/saveAuth', {
        token: data.token,
        user: data.user,
      });

      this.$store.dispatch('auth/setLanguageBasedOnUser', data.user);

      this.statusMessage = this.$t('successMessage');
      this.isSuccess = true;

      const redirectRoute = this.$route.query.redirect || '/perfil'; 
      this.$router.push(redirectRoute);
    } catch (error) {
      this.statusMessage = `${this.$t('errorMessage')}: ${error.message}`;
      this.isSuccess = false;
      console.error('Erro no login:', error.message);
    } finally {
      this.isSubmitting = false;
    }
  },
}

};
</script>

<style scoped>
.login-container {
  max-width: 400px;
  margin: 50px auto;
  padding: 30px;
  background-color: #f5f5f5;
  border-radius: 12px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
  text-align: center;
}

h1 {
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  color: #3a3a3a;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.input-container {
  position: relative;
}

label {
  position: absolute;
  top: 16px;
  left: 12px;
  font-weight: 600;
  font-size: 14px;
  color: #555;
  transition: all 0.3s ease;
  padding: 1px;
}

label.active {
  top: -10px; /* Ajustado para o label subir mais */
  font-size: 12px;
  background-color: #F9F9F9;
  border-radius: 5px;
}

input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-sizing: border-box;
  font-size: 14px;
  padding-top: 22px; /* Para deixar espaço para o label quando o campo é focado */
  transition: border 0.3s ease;
}

input:focus {
  border-color: #C99C5A;
  outline: none;
}

button {
  width: 100%;
  padding: 12px;
  background-color: #C99C5A;
  border: none;
  color: white;
  font-weight: 600;
  font-size: 16px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

button:disabled {
  background-color: #B0A085;
  cursor: not-allowed;
}

button:hover {
  background-color: #b08050;
}

.extra-actions {
  margin-top: 20px;
}

.extra-actions p {
  margin: 5px 0;
  font-size: 14px;
}

router-link {
  color: #C99C5A;
  font-weight: 600;
  text-decoration: none;
}

router-link:hover {
  text-decoration: underline;
}

@media (max-width: 480px) {
  .login-container {
    margin: 20px;
    padding: 25px;
  }

  h1 {
    font-size: 24px;
  }

  input,
  button {
    padding: 10px;
  }
}

.status-message {
  margin-top: 20px;
}

.status-message p {
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
}

.status-message .success {
  color: green;
  background-color: #d4edda;
}

.status-message .error {
  color: red;
  background-color: #f8d7da;
}
</style>
