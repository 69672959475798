import { createI18n } from 'vue-i18n';
import ptBR from '@/locales/pt-br.json';
import esPY from '@/locales/es-py.json';

const savedLocale = localStorage.getItem('locale') || 'pt-BR';

const i18n = createI18n({
  legacy: false, 
  locale: savedLocale, 
  fallbackLocale: 'es-py', 
  messages: {
    'pt-BR': ptBR, 
    'es-PY': esPY, 
  },
});

export default i18n;
