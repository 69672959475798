import CadastroUsuarioView from '@/views/ecommerce/Usuario/CadastroUsuarioView.vue';
import LoginView from '@/views/ecommerce/Usuario/LoginView.vue';
import RecuperarSenhaView from '@/views/ecommerce/Usuario/RecuperarSenha.vue';
import ConfirmarEmailView from '@/views/ecommerce/Usuario/ConfirmarEmailView.vue';
import ResetarSenhaView from '@/views/ecommerce/Usuario/ResetarSenha.vue';

export const authRoutes = [
  {
    path: '/cadastro',
    name: 'cadastro',
    component: CadastroUsuarioView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/recuperar-senha',
    name: 'recuperar-senha',
    component: RecuperarSenhaView
  },
  {
    path: '/app/reset-password/:uuid/:token',
    name: 'reset-password',
    component: ResetarSenhaView
  },
  {
    path: '/app/confirmation-email/:uid/:token',
    name: 'confirmation-email',
    component: ConfirmarEmailView
  }
];
