<template>
  <nav class="header-navigation">
    <div class="container px-0">
      <div class="navigation-container mb-0">
        <div class="row">
          <div class="col-lg-1 d-none d-lg-block">
            <!-- Possivelmente algum conteúdo aqui -->
          </div>
          <div class="col-lg-10 col-md-12">
            <div class="main-menu">
              <nav>
                <ul>
                  <!-- Item CATEGORIAS -->
                  <li class="menu-item-has-children">
                    <a href="#" @click.prevent="toggleMenu('categories')">CATEGORIAS</a>
                    <ul class="sub-menu position-adjusted">
                      <li
                        v-for="category in departments"
                        :key="category.id"
                        class="menu-item-has-children"
                      >
                        <a
                          :href="getCategoryUrl(category)"
                          @click.prevent="selectCategory(category)"
                        >{{ category.name.toUpperCase() }}</a>
                        <ul v-if="category.departments && category.departments.length" class="sub-menu">
                          <li
                            v-for="department in category.departments"
                            :key="department.id"
                          >
                            <a
                              :href="getDepartmentUrl(department, category)"
                              @click.prevent="selectDepartment(department, category)"
                            >
                              {{ department.name.toUpperCase() }}
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>

                  <!-- As demais marcas, como Apple, Xiaomi e Honor, vão para a rota de marca -->
                  <li v-for="brand in brands" :key="brand.id">
                    <a :href="getBrandUrl(brand)" @click.prevent="selectBrand(brand)">
                      {{ brand.nome.toUpperCase() }}
                    </a>
                  </li>

                  <!-- Item XO -->
                  <li class="menu-item-has-children">
                    <a href="#" @click.prevent="selectXoBrand">XO</a>
                    <ul class="sub-menu xo-sub-menu position-adjusted">
                      <li
                        v-for="xoCategory in xoCategories"
                        :key="xoCategory.id"
                        class="menu-item-has-children"
                      >
                        <a
                          :href="getXoCategoryUrl(xoCategory)"
                          @click.prevent="selectXoCategory(xoCategory)"
                        >{{ xoCategory.name.toUpperCase() }}</a>
                        <ul v-if="xoCategory.departments && xoCategory.departments.length" class="sub-menu">
                          <li
                            v-for="xoDepartment in xoCategory.departments"
                            :key="xoDepartment.id"
                          >
                            <a
                              :href="getXoDepartmentUrl(xoDepartment, xoCategory)"
                              @click.prevent="selectXoDepartment(xoDepartment, xoCategory)"
                            >
                              {{ xoDepartment.name.toUpperCase() }}
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div class="mobile-menu d-block d-lg-none"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import axios from 'axios';
import { useRouter } from 'vue-router';

export default {
  name: 'HeaderNavigation',
  data() {
    return {
      departments: [],
      brands: [],
      xoCategories: [],
      menuOpen: false,
      showXoMenu: false,
    };
  },
  async created() {
    try {
      const categoriesResponse = await axios.get(`${process.env.VUE_APP_API_URI}/api/public/categories`);
      this.departments = categoriesResponse.data.map(category => ({
        ...category,
        slug: category.slug,
        departments: category.departments ? [...category.departments].sort((a, b) => a.name.localeCompare(b.name)) : []
      })).sort((a, b) => a.name.localeCompare(b.name));

      const brandsResponse = await axios.get(`${process.env.VUE_APP_API_URI}/api/public/brands`);
      const allBrands = brandsResponse.data;

      const brandNames = ['APPLE', 'XIAOMI', 'HONOR'];
      this.brands = allBrands.filter(brand => brandNames.includes(brand.nome))
        .sort((a, b) => a.nome.localeCompare(b.nome));

      this.brands.push({ nome: 'PERFUMARIA', slug: 'perfumaria' }, { nome: 'Vape', slug: 'vape' });

      this.brands = this.brands.sort((a, b) => a.nome.localeCompare(b.nome));

      const xoCategoriesResponse = await axios.get(`${process.env.VUE_APP_API_URI}/api/public/categories?specials=ONLY`);
      this.xoCategories = xoCategoriesResponse.data.map(category => ({
        ...category,
        slug: category.slug,
        name: category.name.replace("XO ", ""),
        departments: category.departments
          ? category.departments.map(department => ({
            ...department,
            name: department.name.replace("XO ", "")
          })).sort((a, b) => a.name.localeCompare(b.name))
          : []
      })).sort((a, b) => a.name.localeCompare(b.name));
    } catch (error) {
      console.error('Erro ao buscar os departamentos e marcas:', error);
    }
  },

  methods: {
    toggleMenu(type) {
      this.menuOpen = !this.menuOpen;
      this.$emit('menu-toggle', type);
    },
    selectCategory(category) {
      if (category && category.slug) {
        this.$router.push({ name: 'produtos', params: { categoriaSlug: category.slug } });
      }
    },
    selectDepartment(department, category) {
      if (category && category.slug && department && department.slug) {
        this.$router.push({
          name: 'produtos',
          params: {
            categoriaSlug: category.slug,
            departamentoSlug: department.slug
          }
        });
      }
    },
    selectXoBrand() {
      this.$router.push({
        name: 'especial',
        params: { brandSlug: 'xo' }
      });
    },
    selectXoCategory(category) {
      this.$router.push({
        name: 'especial',
        params: { brandSlug: 'xo', categoriaSlug: category.slug }
      });
      this.$emit('xo-category-selected', category);
    },
    selectXoDepartment(xoDepartment, xoCategory) {
      if (xoCategory && xoCategory.slug && xoDepartment && xoDepartment.slug) {
        this.$router.push({
          name: 'especial',
          params: {
            brandSlug: 'xo',
            categoriaSlug: xoCategory.slug,
            departamentoSlug: xoDepartment.slug
          },
          query: { departmentId: xoDepartment.id }
        }).then(async () => {
          try {
            const products = await fetchProductsByDepartmentId(xoCategory.id, xoDepartment.slug, {});
            console.log('Produtos recebidos:', products);
            this.filteredProducts = products;
          } catch (error) {
            console.error('Erro ao buscar produtos após selecionar o departamento:', error);
          }
        });

        this.$emit('xo-department-selected', { xoDepartment, xoCategory });
      } else {
        console.error('Missing slug for category or department:', xoCategory, xoDepartment);
      }
    },
   
    selectBrand(brand) {
      // Se a marca for PERFUMARIA ou Vape, redireciona para a rota de produtos com slug específico
      if (brand.nome === 'PERFUMARIA' || brand.nome === 'Vape') {
        this.$router.push({ name: 'produtos', params: { categoriaSlug: brand.slug } });
      } else {
        this.$router.push({ name: 'marca', params: { brandSlug: brand.slug } });
      }
    },

    // Métodos para gerar URLs
    getCategoryUrl(category) {
      return this.$router.resolve({ name: 'produtos', params: { categoriaSlug: category.slug } }).href;
    },
    getDepartmentUrl(department, category) {
      return this.$router.resolve({
        name: 'produtos',
        params: { categoriaSlug: category.slug, departamentoSlug: department.slug }
      }).href;
    },
    getXoCategoryUrl(category) {
      return this.$router.resolve({
        name: 'especial',
        params: { brandSlug: 'xo', categoriaSlug: category.slug }
      }).href;
    },
    getXoDepartmentUrl(department, category) {
      return this.$router.resolve({
        name: 'especial',
        params: { brandSlug: 'xo', categoriaSlug: category.slug, departamentoSlug: department.slug }
      }).href;
    },
    getBrandUrl(brand) {
      return this.$router.resolve({ name: 'marca', params: { brandSlug: brand.slug } }).href;
    }
  }
};
</script>

<style scoped>
.header-navigation {
  background-color: #000000;
  padding: 0 20px;
  align-items: flex-start;
}

.position-adjusted {
  transform: translateY(-18px);
}

.header-navigation .container {
  max-width: 1500px;
  padding: 0;
}

.header-navigation .main-menu ul li a {
  font-size: 18px; /* Diminuir o tamanho da fonte de 20px para 18px */
}

.header-navigation .sub-menu li a {
  font-size: 16px; /* Ajuste do tamanho das fontes do submenu */
}

.navigation-container {
  padding: 8px 0;
}

.xo-sub-menu {
  left: auto;
  right: 0;
}

.xo-sub-menu .sub-menu {
  right: 100% !important;
  left: auto !important;
  top: 0 !important;
}

.main-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px; /* Ajustado para o tamanho menor */
  height: 50px;
  align-items: flex-end;
}

.main-menu ul {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
}

.main-menu ul li {
  position: relative;
}

.xo-sub-menu .menu-item-has-children>a {
  position: relative;
  padding-left: 30px;
  padding-right: 20px;
  text-align: right;
}

.xo-sub-menu .menu-item-has-children>a::after {
  content: '▼';
  font-size: 14px;
  color: #b6b6b6;
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%) rotate(180deg);
}

.main-menu ul li a {
  position: relative;
  padding: 15px 25px;
  font-size: 18px; /* Ajustado o tamanho da fonte */
  text-decoration: none;
  color: #b6b6b6;
  display: inline-block;
}

.main-menu ul li a::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 3px;
  background-color: #C99C5A;
  transition: transform 0.3s ease;
  transform: scaleX(0);
  transform-origin: top left;
}

.main-menu ul li a:hover::before {
  transform: scaleX(1);
}

.menu-item-has-children>a::after {
  content: ' ▼';
  font-size: 14px;
  color: #b6b6b6;
  margin-left: 7px;
}

.menu-item {
  position: relative;
}

.menu-item-has-children {
  position: relative;
}

.menu-item-has-children>a::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 3px;
  background-color: #C99C5A;
  transition: transform 0.3s ease;
  transform: scaleX(0);
  transform-origin: top left;
}

.menu-item-has-children:hover>a::before {
  transform: scaleX(1);
}

.menu-item-has-children:hover .sub-menu {
  display: block;
}

.menu-item-has-children .sub-menu {
  display: block;
}

.sub-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.sub-menu li {
  border-bottom: 1px solid #ddd;
  list-style: none;
}

.sub-menu li:last-child {
  border-bottom: none;
}

.sub-menu li a {
  font-size: 16px; /* Ajustado o tamanho das letras do submenu */
  color: #333;
  text-decoration: none;
  display: block;
  transition: background-color 0.3s;
  width: 100%;
  padding: 10px 15px;
}

.sub-menu li a:hover {
  background-color: #adadad;
  width: 100%;
}

.menu-toggle {
  background: none;
  border: none;
  color: #b6b6b6;
  font-size: 28px;
  cursor: pointer;
}

.side-menu {
  position: fixed;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  transition: left 0.3s ease;
  z-index: 1000;
}

.side-menu.menu-open {
  left: 0;
}

.close-menu {
  background: none;
  border: none;
  font-size: 48px;
  color: #333;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}

.side-menu nav {
  padding: 80px 20px 20px 20px;
}

.side-menu ul {
  list-style: none;
  padding: 0;
}

.side-menu ul li {
  margin-bottom: 30px;
}

.side-menu ul li a {
  text-decoration: none;
  color: #333;
  font-size: 20px;
}

.side-menu ul li a:hover {
  color: #C99C5A;
}

@media (max-width: 991px) {
  .header-navigation {
    background-color: #ffffff;
  }

  .main-menu ul {
    display: block;
  }

  .main-menu ul li {
    margin-right: 0;
  }

  .main-menu ul li a {
    padding: 15px;
    font-size: 14px;
    color: #333333;
  }

  .main-menu ul li a:hover {
    color: #000000;
  }
}

/* Novo ajuste para o item CATEGORIAS */
.main-menu ul li:first-child a {
  padding-right: 50px; /* Adiciona mais espaço à direita do item "CATEGORIAS" */
}
</style>
