<template>
    <div class="admin-dashboard" @click="closeSidebar">
        <!-- Botão de Menu (Hamburger) -->
        <div class="hamburger-menu" @click="toggleSidebar">
            <i data-feather="menu"></i>
        </div>

        <!-- Sidebar -->
        <div class="sidebar" :class="{ 'open': sidebarOpen, 'mobile': isMobile }" ref="sidebarRef">
            <ul class="sidebar-menu">
                <li>
                    <router-link to="/perfil/dados-pessoais">
                        <i data-feather="user"></i> {{ $t('admin.personal_data') }}
                    </router-link>
                </li>
                <li>
                    <router-link to="/perfil/meus-pedidos">
                        <i data-feather="shopping-cart"></i> {{ $t('admin.my_orders') }}
                    </router-link>
                </li>
                <li>
                    <router-link to="/perfil/endereco">
                        <i data-feather="map-pin"></i> {{ $t('admin.delivery_address') }}
                    </router-link>
                </li>
                <li>
                    <router-link to="/perfil/alterar-senha">
                        <i data-feather="lock"></i> {{ $t('admin.change_password') }}
                    </router-link>
                </li>
                <li>
                    <a @click.prevent="handleLogout" class="logout-link">
                        <i data-feather="log-out" class="logout-icon"></i>
                        <span class="logout-text">{{ $t('admin.logout') }}</span>
                    </a>
                </li>
            </ul>
        </div>

        <!-- Mensagens de erro -->
        <div v-if="errorMessages.length" class="error-messages">
            <ul>
                <li v-for="(message, index) in errorMessages" :key="index" class="error-message">
                    <i data-feather="alert-circle"></i> {{ message }}
                </li>
            </ul>
        </div>

        <!-- Main Content -->
        <div class="component">
            <component :is="currentComponent"></component>
        </div>

    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Profile from '@/views/ecommerce/Usuario/Perfil.vue';
import Pedidos from '@/views/ecommerce/Usuario/Pedidos.vue';
import Endereco from '@/views/ecommerce/Usuario/Endereco.vue';
import AlterarSenha from '@/views/ecommerce/Usuario/AlterarSenha.vue';

export default {
    name: 'UserView',
    components: {
        Profile,
        Pedidos,
        Endereco,
        AlterarSenha,
    },
    data() {
        return {
            errorMessages: [],
            sidebarOpen: false,
            isMobile: false,
            currentComponent: 'Profile',
        };
    },
    computed: {
        ...mapGetters({
            isAuthenticated: 'auth/isAuthenticated',
            getUser: 'auth/getUser',
            getToken: 'auth/getToken',
        }),
        user() {
            return this.getUser || {};
        },
    },
    methods: {
        ...mapActions(['fetchUser', 'logout']),

        handleLogout() {
            this.$store.dispatch('auth/logout');
            this.$router.push('/login');
        },

        setCurrentComponent() {
            switch (this.$route.name) {
                case 'perfil-dados-pessoais':
                    this.currentComponent = 'Profile';
                    break;
                case 'perfil-meus-pedidos':
                    this.currentComponent = 'Pedidos';
                    break;
                case 'perfil-endereco':
                    this.currentComponent = 'Endereco';
                    break;
                case 'perfil-alterar-senha':
                    this.currentComponent = 'AlterarSenha';
                    break;
                default:
                    this.currentComponent = 'Profile';
            }
        },

        toggleSidebar() {
            this.sidebarOpen = !this.sidebarOpen;
        },

        closeSidebar() {
            if (this.isMobile) {
                this.sidebarOpen = false;
            }
        },

        handleResize() {
            this.isMobile = window.innerWidth <= 768;
            if (!this.isMobile) {
                this.sidebarOpen = true;
            }
        },

        renderIcons() {
            this.$nextTick(() => {
                if (typeof feather !== 'undefined') {
                    feather.replace();
                }
            });
        },
    },
    watch: {
        $route() {
            this.setCurrentComponent();
        },
        sidebarOpen() {
            this.renderIcons();
        },
    },
    created() {
        this.setCurrentComponent();
    },
    mounted() {
        this.handleResize();
        window.addEventListener('resize', this.handleResize);
        this.renderIcons();
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },
};
</script>


<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');

.admin-dashboard {
    display: flex;
    flex-direction: row;
    min-height: 100vh;
    max-width: 1200px;
    margin: 0 auto;
    justify-content: flex-start;
    padding: 20px;
    font-family: 'Poppins', sans-serif;
    overflow: hidden;
    gap: 20px
}

.component {
    width: 100%;
}

.sidebar {
    width: 230px;
    background-color: #fafafa;
    color: #333;
    padding-top: 20px;
    position: relative;
    z-index: 1;
    flex-shrink: 0;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.sidebar.mobile {
    width: 200px;
}

.sidebar-menu {
    list-style: none;
    margin: 0;
    font-size: 0.9rem;
}

.sidebar-menu li {
    padding: 0.8rem 1rem;
    border-bottom: 1px solid #eaeaea;
}

.logout-link {
    display: flex;
    align-items: center;
    text-decoration: none;
}

.logout-icon,
.logout-text {
    color: red;
}

.logout-link:hover {
    color: #f44336;
}

.logout-icon {
    margin-right: 8px;
}

.sidebar-menu a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #333;
    padding: 0.8rem;
    transition: background-color 0.3s, color 0.3s;
    border-radius: 8px;
}

.sidebar-menu a i {
    margin-right: 8px;
    width: 20px;
    height: 20px;
}

.sidebar-menu .router-link-active {
    background-color: #e0e0e0;
    color: #5c5c5c;
    font-weight: bold;
}

.sidebar-menu a:hover {
    background-color: #e0e0e0;
    color: #5c5c5c;
}


.error-messages {
    margin-top: 20px;
    padding: 15px;
    background-color: #f8d7da;
    color: #721c24;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.error-message i {
    margin-right: 10px;
}

@media (max-width: 768px) {
    .sidebar {
        width: 100%;
        padding-bottom: 20px;
    }

    .sidebar-menu {
        display: flex;
        justify-content: space-around;
        padding: 0;
        margin: 0;
        flex-wrap: wrap;
    }

    .sidebar-menu li {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0.5rem 0;
        text-align: center;
    }

    .sidebar-menu a {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 0.9rem;
        width: auto;
        min-width: 70px;
    }

    .main-content {
        margin-top: 20px;
        padding: 10px;
    }

    .admin-dashboard {
        flex-direction: column;
        height: auto;
    }
}
</style>
